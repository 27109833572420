import React, { useState, useEffect } from "react";
import moment from "moment";
import { imagePath } from "../../../constants/imageUrl";
import DatePicker, { registerLocale } from "react-datepicker";

import pt from "date-fns/locale/pt";

import "react-datepicker/dist/react-datepicker.css";
import { validation } from "../../../constants/constant";
import {
  AddListSpotApiCall,
  getListSpotApiCall,
  getSpotDetailsApiCall,
} from "../../../_services/listSpot/listSpot.service";
import ApiLoader from "../../../common/ApiLoader";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { getAllLookupApiCall } from "../../../_services/lookups/lookups.service";
import { t } from "i18next";
import dateFormat, { LOCALE } from "../../../constants/dateFormat";
import { createLogs } from "../../../_services/logs/logs.service";
import { authTokenHeader } from "../../../_services/auth-header";

registerLocale("pt", pt);

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 150) : text}
      {text.length > 150 && (
        <span className="read-or-hide">
          <a
            className="text-link fw-medium text-decoration-none text-nowrap pointer-cursor"
            onClick={toggleReadMore}
          >
            {isReadMore ? "...read more" : " show less"}
          </a>
        </span>
      )}
    </p>
  );
};

function OperatingHours({
  activeListSpotFun,
  handler,
  stepsCompleted,
  activeListSpot,
  disableListSpotFun,
  setActiveListSpot,
  setCompletedStep,
}) {
  const params = useParams();
  const [radioTabs, setRadioTabs] = useState("everyday");
  const [cancelationPolicy, setCancelationPolicy] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [minBookingHr, setMinBookingHr] = useState("");
  const [cancellationPolicy, setCancellationPolicy] = useState([]);

  const [loader, setLoader] = useState(false);

  // All Data For Weekend
  const [onlyWeekend, setOnlyWeekend] = useState({
    saturday: [],
    sunday: [],
  });
  const [startTime, setStartTime] = useState({
    saturday: "",
    sunday: "",
  });
  const [endTime, setEndTime] = useState({
    saturday: "",
    sunday: "",
  });
  const [check, setCheck] = useState([]);
  const [slotBooked, setSlotBooked] = useState({
    saturday: "",
    sunday: "",
  });

  // All Data For Selected Days
  const [onlySelectedDays, setOnlySelectedDays] = useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });
  const [startTimeForDays, setStartTimeForDays] = useState({
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  });
  const [endTimeForDays, setEndTimeForDays] = useState({
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  });
  const [checkForDays, setCheckForDays] = useState([]);
  const [slotBookedForDays, setSlotBookedForDays] = useState({
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  });

  // All data for Calander
  const [startDate, setStartDate] = useState(moment().add(1, "days").toDate());
  const [endDate, setEndDate] = useState(moment().add(1, "days").toDate());
  // Only for showing on To
  const [showEndDate, setShowEndDate] = useState();
  const onChangeCalendar = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setShowEndDate(end);
    setEndDate(end == null ? start : end);
  };
  const [calendarStartTime, setCalendarStartTime] = useState("");
  const [calendarEndTime, setCalendarEndTime] = useState("");
  const [calandarData, setCalandarData] = useState([]);

  // Validaiton
  const [submitted, setSubmitted] = useState(false);
  const [submittedSlecetedDays, setSubmittedSlecetedDays] = useState(false);
  const [submittedCalendar, setSubmittedCalendar] = useState(false);
  const [calanderValidate, setCalanderValidate] = useState(false);

  // For check wether data is weekend or selected days
  const checkForWeekdays = (data) => {
    let weekday = false;
    let weekdaysArray = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
    ];

    let selectedDays = [];
    if (data?.qs_operation_hours?.weekdays) {
      data?.qs_operation_hours?.weekdays.map((row) => {
        let foundWeekday = weekdaysArray.find((e) => {
          return e == row.day;
        });
        if (foundWeekday) {
          weekday = true;
        }
        selectedDays.push(row.day);
      });

      // Radio tabs for selected days
      if (weekday) {
        setRadioTabs("selectedDays");
        // Array for for weekend checkbox
        setCheckForDays(selectedDays);
      }

      // radio tabs for weekend
      if (!weekday) {
        setRadioTabs("weekend");
        // Array set for selected days
        setCheck(selectedDays);
      }
    }

    // Radio Tabs set for everyday
    if (data?.qs_operation_hours?.everyday) {
      setRadioTabs("everyday");
    }
    // Radio tabs set for calendar
    if (data?.qs_operation_hours?.calendar) {
      setRadioTabs("calandar");
    }

    return weekday;
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  // Calling get api for showing data
  useEffect(() => {
    // For edit screen
    if (params.id) {
      setLoader(true);
      getSpotDetailsApiCall(params.id)
        .then((response) => {
          setLoader(false);
          let data = response?.data?.data[0];
          setMinBookingHr(data?.qs_charges?.minBookingHr);
          if (data?.qs_operation_hours !== null) {
            // What was selected weekned or weekdays, if true then selected days else weekend
            let weekendOrSelectedDays = checkForWeekdays(data);

            // If Get data is for selected days
            if (weekendOrSelectedDays) {
              // For converting data to my payload
              let week = {};
              let day = [];
              data?.qs_operation_hours?.weekdays?.map((weekday) => {
                let newTime = [];
                weekday.slots.map((timeItem) => {
                  let endTime = moment(timeItem.end_time, "HH:mm")
                    .local()
                    .format("HH:mm");
                  let startTime = moment(timeItem.start_time, "HH:mm")
                    .local()
                    .format("HH:mm");
                  let newOneTime = startTime + "-" + endTime;
                  newTime.push(newOneTime);
                });

                week[weekday.day] = weekday.slots;
                day[weekday.day] = newTime;
              });

              setOnlySelectedDays(Object.assign(onlySelectedDays, { ...day }));
            }

            // If Get data is for weekend
            if (
              (!data?.qs_operation_hours?.everyday ||
                !data?.qs_operation_hours?.calendar) &&
              !weekendOrSelectedDays
            ) {
              // For converting data to my payload
              let week = {};
              let day = [];
              data?.qs_operation_hours?.weekdays?.map((weekday) => {
                let newTime = [];
                weekday.slots.map((timeItem) => {
                  let endTime = moment(timeItem.end_time, "HH:mm")
                    .local()
                    .format("HH:mm");
                  let startTime = moment(timeItem.start_time, "HH:mm")
                    .local()
                    .format("HH:mm");
                  let newOneTime = startTime + " - " + endTime;
                  newTime.push(newOneTime);
                });

                week[weekday.day] = weekday.slots;
                day[weekday.day] = newTime;
              });

              setOnlyWeekend(Object.assign(onlyWeekend, { ...day }));
            }

            // If Get data is for calendar
            if (data?.qs_operation_hours?.calendar) {
              // For converting data to my payload
              let cal = [];
              let day = [];
              data?.qs_operation_hours?.calendar?.map((weekday) => {
                let newTime = [];
                weekday.slots.map((timeItem) => {
                  let endTime = moment(timeItem.end_time, "HH:mm")
                    .local()
                    .format("HH:mm");
                  let startTime = moment(timeItem.start_time, "HH:mm")
                    .local()
                    .format("HH:mm");
                  let newOneTime = startTime + " - " + endTime;

                  newTime.push(newOneTime);
                });

                // week[weekday.day] = weekday.slots;
                cal.push({
                  startDate: weekday.start_date,
                  endDate: weekday.end_date,
                  slots: newTime,
                });
              });
              setCalandarData(cal);
            }

            // get cancelation policy
            if (data?.cpolicy_id !== null) {
              setCancelationPolicy(data?.cpolicy_id);
            }
          }
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response));
          setLoader(false);
        });
    } else {
      // For add spot
      if (handler !== "") {
        // alert("helo");
        // get all data
        setLoader(true);
        getListSpotApiCall(handler?.quickspot_id).then((response) => {
          if (response.status === 200) {
            setLoader(false);
            let data = response?.data?.data[0];
            setMinBookingHr(data?.qs_charges?.minBookingHr);
            if (data?.qs_operation_hours !== null) {
              // What was selected weekend or weekdays, if true then selected days else weekend
              let weekendOrSelectedDays = checkForWeekdays(data);

              // If Get data is for selected days
              if (weekendOrSelectedDays) {
                // For converting data to my payload
                let week = {};
                let day = [];
                data?.qs_operation_hours?.weekdays?.map((weekday) => {
                  let newTime = [];
                  weekday.slots.map((timeItem) => {
                    let endTime = moment(timeItem.end_time, "hh:mm")
                      .local()
                      .format("HH:mm");
                    let startTime = moment(timeItem.start_time, "HH:mm")
                      .local()
                      .format("HH:mm");
                    let newOneTime = startTime + "-" + endTime;
                    newTime.push(newOneTime);
                  });

                  week[weekday.day] = weekday.slots;
                  day[weekday.day] = newTime;
                });

                setOnlySelectedDays(
                  Object.assign(onlySelectedDays, { ...day })
                );
              }

              // If Get data is for weekend
              if (
                (!data?.qs_operation_hours?.everyday ||
                  !data?.qs_operation_hours?.calendar) &&
                !weekendOrSelectedDays
              ) {
                // For converting data to my payload
                let week = {};
                let day = [];
                data?.qs_operation_hours?.weekdays?.map((weekday) => {
                  let newTime = [];
                  weekday.slots.map((timeItem) => {
                    let endTime = moment(timeItem.end_time, "HH:mm")
                      .local()
                      .format("HH:mm");

                    let startTime = moment(timeItem.start_time, "HH:mm")
                      .local()
                      .format("HH:mm");

                    let newOneTime = startTime + "-" + endTime;
                    newTime.push(newOneTime);
                  });

                  week[weekday.day] = weekday.slots;
                  day[weekday.day] = newTime;
                });

                setOnlyWeekend(Object.assign(onlyWeekend, { ...day }));
              }

              // // If Get data is for calendar
              // if (data?.qs_operation_hours?.calendar) {
              //   setCalandarData(data?.qs_operation_hours?.calendar);
              // }
              // If Get data is for calendar
              if (data?.qs_operation_hours?.calendar) {
                // For converting data to my payload
                let cal = [];
                let day = [];
                data?.qs_operation_hours?.calendar?.map((weekday) => {
                  let newTime = [];
                  weekday.slots.map((timeItem) => {
                    let endTime = moment(timeItem.end_time, "HH:mm")
                      .local()
                      .format("HH:mm");

                    let startTime = moment(timeItem.start_time, "HH:mm")
                      .local()
                      .format("HH:mm");

                    let newOneTime = startTime + " - " + endTime;

                    newTime.push(newOneTime);
                  });

                  // week[weekday.day] = weekday.slots;
                  cal.push({
                    startDate: weekday.start_date,
                    endDate: weekday.end_date,
                    slots: newTime,
                  });
                });
                setCalandarData(cal);
              }

              // get cancelation policy
              setCancelationPolicy(data?.cpolicy_id);
            }
          }
          if (response.status === 201) {
            toast.error(response.message);
          }
        });
      } else {
      }
    }
  }, [activeListSpot === "spot-operationHr"]);

  //cancellation policy
  useEffect(() => {
    getAllLookupApiCall({ qs_lookup_key: `qs_cancellation_policy` }).then(
      (response) => {
        setCancellationPolicy(response.data);
      }
    );
  }, []);

  // Weekend Checkbox
  const handleChangeCheckbox = (e, day, index) => {
    if (day === "saturday" && e.target.checked) {
      setCheck([...check, "saturday"]);
    }
    if (day === "saturday" && !e.target.checked && check.includes("saturday")) {
      const array = [...check];
      var index = array.indexOf("saturday");
      if (index !== -1) {
        array.splice(index, 1);
        setCheck(array);
      }
    }

    if (day === "sunday" && e.target.checked) {
      // const chk = [...check, "sunday"]
      setCheck([...check, "sunday"]);
    }
    if (day === "sunday" && !e.target.checked && check.includes("sunday")) {
      // check.slice(index,1);
      const array = [...check];
      var index = array.indexOf("sunday");
      if (index !== -1) {
        array.splice(index, 1);
        setCheck(array);
      }
    }
  };
  // Weekend Start Time
  const selectedStartTime = (day) => {
    return startTime[day];
    // if (day === "saturday") {
    //   return startTime.saturday
    // }
    // if (day === "sunday") {
    //   return startTime.sunday
    // }
  };
  // Weekend Change Start Time
  const handleChangeStartTime = (date, day) => {
    setStartTime({ ...startTime, [day]: date });
    // if (day === "saturday") {
    //   setStartTime({ ...startTime, saturday: date })
    // }
    // if (day === "sunday") {
    //   setStartTime({ ...startTime, sunday: date })
    // }
  };
  const selectedEndTime = (day) => {
    return endTime[day];
  };
  // Weekend Change End Time
  const handleChangeEndTime = (date, day) => {
    setEndTime({ ...endTime, [day]: date });
    // if (day === "saturday") {
    //   setEndTime({ ...endTime, saturday: date })
    // }
    // if (day === "sunday") {
    //   setEndTime({ ...endTime, sunday: date })
    // }
  };
  // Weekend End Time 2:30 pm
  const timeFormat = (time) => {
    return moment(time).format("HH:mm");
  };
  // Weekend Time Validations
  const validateTime = (day) => {
    // let valid = true;
    let currentStartTime = moment(startTime[day], ["h:mm A"]).format("HH:mm"); // Output ="14:00" In 24hr format StartTime
    let currentEndTime = moment(endTime[day], ["h:mm A"]).format("HH:mm"); // Output ="15:00" In 24hr format End Time
    let invalidSlots = onlyWeekend[day]
      .map((item) => {
        let time = item.split("-");
        let previousStartTime = moment(time[0], ["h:mm A"]).format("HH:mm"); // previous Start Time
        let previousEndTime = moment(time[1], ["h:mm A"]).format("HH:mm");
        if (
          (currentStartTime < previousStartTime &&
            currentEndTime < previousStartTime) ||
          (currentStartTime > previousEndTime &&
            currentEndTime > previousEndTime)
        ) {
          return false;
        } else {
          return item;
        }
      })
      .filter((item) => {
        return item ? item : false;
      });
    return invalidSlots.length === 0;
  };
  // Start time Greater Than end Time
  const startGreaterThanEndTime = (startTime, endTime) => {
    return moment(startTime, "h:mma").isAfter(moment(endTime, "h:mma"));
  };
  // min booking hr check
  const minHrBookingChk = (day) => {
    let start_time = moment(startTime[day], "hh:mm a").format("hh:mm a");
    let end_time = moment(endTime[day], "hh:mm a").format("hh:mm a");
    var duration = moment.duration(
      moment(start_time, "hh:mm a").diff(moment(end_time, "hh:mm a"))
    );
    var hours = parseInt(duration.asHours()); //duration in hours
    // var minutes = parseInt(duration.asMinutes()) % 60; //duration in minutes
    var check = Math.abs(hours) < minBookingHr ? true : false;

    return check;
  };
  // Weekend Add Time
  const addOnlyWeekendTime = (day) => {
    if (startTime[day] !== "" && endTime[day] !== "") {
      const time =
        timeFormat(startTime[day]) + " - " + timeFormat(endTime[day]);
      if (startGreaterThanEndTime(startTime[day], endTime[day])) {
        return;
      }
      if (timeFormat(startTime[day]) === timeFormat(endTime[day])) {
        return;
      }

      if (minHrBookingChk(day)) {
        setStartTime({ ...startTime, [day]: "" });
        setEndTime({ ...endTime, [day]: "" });
        toast.error(
          `${t("minimum_booking_is")} ${minBookingHr} ${t("hours")}.`
        );
        return;
      }
      if (!validateTime(day)) {
        toast.error(
          t("this_slot_is_already_taken,_please_select_different_slot.")
        );
        setStartTime({ ...startTime, [day]: "" });
        setEndTime({ ...endTime, [day]: "" });
        // setSlotBooked({ ...slotBooked, [day]: true })
        return;
      } else {
        // setSlotBooked({ ...slotBooked, [day]: false })
      }
      var newTime = [...onlyWeekend[day], time];
      setOnlyWeekend({ ...onlyWeekend, [day]: newTime });
      setStartTime({ ...startTime, [day]: "" });
      setEndTime({ ...endTime, [day]: "" });
      setSlotBooked({ ...slotBooked, [day]: false });
    }
  };
  // Weekend Remove Time
  const removeTime = (date, day) => {
    const array = [...onlyWeekend[day]];
    var index = array.indexOf(date);
    if (index !== -1) {
      array.splice(index, 1);
      setOnlyWeekend({ ...onlyWeekend, [day]: array });
    }
  };

  // Selected Days Checkbox
  const handleChangeCheckboxForDays = (e, day, index) => {
    // monday
    if (day === "monday" && e.target.checked) {
      setCheckForDays([...checkForDays, "monday"]);
    }
    if (
      day === "monday" &&
      !e.target.checked &&
      checkForDays.includes("monday")
    ) {
      const array = [...checkForDays];
      var index = array.indexOf("monday");
      if (index !== -1) {
        array.splice(index, 1);
        setCheckForDays(array);
      }
    }

    // Tuesday
    if (day === "tuesday" && e.target.checked) {
      setCheckForDays([...checkForDays, "tuesday"]);
    }
    if (
      day === "tuesday" &&
      !e.target.checked &&
      checkForDays.includes("tuesday")
    ) {
      const array = [...checkForDays];
      var index = array.indexOf("tuesday");
      if (index !== -1) {
        array.splice(index, 1);
        setCheckForDays(array);
      }
    }

    // Wednesday
    if (day === "wednesday" && e.target.checked) {
      setCheckForDays([...checkForDays, "wednesday"]);
    }
    if (
      day === "wednesday" &&
      !e.target.checked &&
      checkForDays.includes("wednesday")
    ) {
      const array = [...checkForDays];
      var index = array.indexOf("wednesday");
      if (index !== -1) {
        array.splice(index, 1);
        setCheckForDays(array);
      }
    }

    // Thursday
    if (day === "thursday" && e.target.checked) {
      setCheckForDays([...checkForDays, "thursday"]);
    }
    if (
      day === "thursday" &&
      !e.target.checked &&
      checkForDays.includes("thursday")
    ) {
      const array = [...checkForDays];
      var index = array.indexOf("thursday");
      if (index !== -1) {
        array.splice(index, 1);
        setCheckForDays(array);
      }
    }

    // Friday
    if (day === "friday" && e.target.checked) {
      setCheckForDays([...checkForDays, "friday"]);
    }
    if (
      day === "friday" &&
      !e.target.checked &&
      checkForDays.includes("friday")
    ) {
      const array = [...checkForDays];
      var index = array.indexOf("friday");
      if (index !== -1) {
        array.splice(index, 1);
        setCheckForDays(array);
      }
    }

    // Saturday
    if (day === "saturday" && e.target.checked) {
      setCheckForDays([...checkForDays, "saturday"]);
    }
    if (
      day === "saturday" &&
      !e.target.checked &&
      checkForDays.includes("saturday")
    ) {
      const array = [...checkForDays];
      var index = array.indexOf("saturday");
      if (index !== -1) {
        array.splice(index, 1);
        setCheckForDays(array);
      }
    }

    // Sunday
    if (day === "sunday" && e.target.checked) {
      setCheckForDays([...checkForDays, "sunday"]);
    }
    if (
      day === "sunday" &&
      !e.target.checked &&
      checkForDays.includes("sunday")
    ) {
      const array = [...checkForDays];
      var index = array.indexOf("sunday");
      if (index !== -1) {
        array.splice(index, 1);
        setCheckForDays(array);
      }
    }
  };
  // Selected Days Start Time
  const selectedStartTimeForDays = (day) => {
    return startTimeForDays[day];
    // if (day === "monday") {
    //   return startTimeForDays.monday
    // }
  };
  // Selected Days change Start Time
  const handleChangeStartTimeForDays = (date, day) => {
    setStartTimeForDays({ ...startTimeForDays, [day]: date });
    // if (day === "monday") {
    //   setStartTimeForDays({ ...startTimeForDays, monday: date })
    // }
  };
  // Selected Days End Time
  const selectedEndTimeForDays = (day) => {
    return endTimeForDays[day];
    // if (day === "monday") {
    //   return endTimeForDays.monday
    // }
  };
  // Selected Days Change End Time
  const handleChangeEndTimeForDays = (date, day) => {
    setEndTimeForDays({ ...endTimeForDays, [day]: date });

    // if (day === "monday") {
    //   setEndTimeForDays({ ...endTimeForDays, monday: date })
    // }
  };
  // Weekend Time Validations
  const validateTimeForDays = (day) => {
    let valid = true;
    let currentStartTime = moment(startTimeForDays[day], ["h:mm A"]).format(
      "HH:mm"
    ); // Output ="14:00" In 24hr format StartTime
    let currentEndTime = moment(endTimeForDays[day], ["h:mm A"]).format(
      "HH:mm"
    ); // Output ="15:00" In 24hr format End Time
    let invalidSlots = onlySelectedDays[day]
      .map((item) => {
        let time = item.split("-");
        let previousStartTime = moment(time[0], ["h:mm A"]).format("HH:mm"); // previous Start Time
        let previousEndTime = moment(time[1], ["h:mm A"]).format("HH:mm");
        if (
          (currentStartTime < previousStartTime &&
            currentEndTime < previousStartTime) ||
          (currentStartTime > previousEndTime &&
            currentEndTime > previousEndTime)
        ) {
          // valid = true
          return false;
        } else {
          valid = false;
          return item;
        }
      })
      .filter((item) => {
        return item ? item : false;
      });
    return invalidSlots.length === 0;
  };
  // min booking hr check
  const minHrBookingChkForSelectedDays = (day) => {
    let start_time = moment(startTimeForDays[day], "hh:mm a").format("hh:mm a");
    let end_time = moment(endTimeForDays[day], "hh:mm a").format("hh:mm a");
    var duration = moment.duration(
      moment(start_time, "hh:mm a").diff(moment(end_time, "hh:mm a"))
    );
    var hours = parseInt(duration.asHours()); //duration in hours
    // var minutes = parseInt(duration.asMinutes()) % 60; //duration in minutes
    var check = Math.abs(hours) < minBookingHr ? true : false;

    return check;
  };
  // Selected Day Add Time
  const addOnlySelectedDaysTime = (index, day) => {
    if (startTimeForDays[day] !== "" && endTimeForDays[day] !== "") {
      const time =
        timeFormat(startTimeForDays[day]) +
        " - " +
        timeFormat(endTimeForDays[day]);
      if (startGreaterThanEndTime(startTimeForDays[day], endTimeForDays[day])) {
        return;
      }
      if (
        timeFormat(startTimeForDays[day]) === timeFormat(endTimeForDays[day])
      ) {
        return;
      }
      if (minHrBookingChkForSelectedDays(day)) {
        setStartTimeForDays({ ...startTimeForDays, [day]: "" });
        setEndTimeForDays({ ...endTimeForDays, [day]: "" });
        toast.error(
          `${t("minimum_booking_is")} ${minBookingHr} ${t("hours")}.`
        );
        return;
      }
      if (!validateTimeForDays(day)) {
        toast.error(
          t("this_slot_is_already_taken,_please_select_different_slot.")
        );
        setStartTimeForDays({ ...startTimeForDays, [day]: "" });
        setEndTimeForDays({ ...endTimeForDays, [day]: "" });
        // setSlotBookedForDays({ ...slotBookedForDays, [day]: true })
        return;
      }
      var newTime = [...onlySelectedDays[day], time];
      setOnlySelectedDays({ ...onlySelectedDays, [day]: newTime });
      setStartTimeForDays({ ...startTimeForDays, [day]: "" });
      setEndTimeForDays({ ...endTimeForDays, [day]: "" });
      setSlotBookedForDays({ ...slotBookedForDays, [day]: false });
    }
  };
  // Selected Days Remove Time
  const removeTimeForDays = (date, day) => {
    const array = [...onlySelectedDays[day]];
    var index = array.indexOf(date);
    if (index !== -1) {
      array.splice(index, 1);
      setOnlySelectedDays({ ...onlySelectedDays, [day]: array });
    }
  };

  // Calander Time ValidationsstartGreaterThanEndTime
  const validateTimeForCalendar = (item) => {
    let valid = true;
    let currentStartTime = moment(calendarStartTime, ["h:mm A"]).format(
      "HH:mm"
    ); // Output ="14:00" In 24hr format StartTime
    let currentEndTime = moment(calendarEndTime, ["h:mm A"]).format("HH:mm"); // Output ="15:00" In 24hr format End Time
    // calandarData.map((item) => {

    // if(moment(startDate).format("MMMM DD, YYYY") === item.startDate && moment(endDate).format("MMMM DD, YYYY") === item.endDate)
    let invalidSlots = item.slots
      .map((slotItem) => {
        let time = slotItem.split("-");
        let previousStartTime = moment(time[0], ["h:mm A"]).format("HH:mm"); // previous Start Time
        let previousEndTime = moment(time[1], ["h:mm A"]).format("HH:mm");
        if (
          (currentStartTime < previousStartTime &&
            currentEndTime < previousStartTime) ||
          (currentStartTime > previousEndTime &&
            currentEndTime > previousEndTime)
        ) {
          // valid = true
          return false;
        } else {
          valid = false;
          return item;
        }
      })
      .filter((e) => {
        return e ? e : false;
      });

    return invalidSlots.length == 0;
    // })
  };

  const validateStartAndEndDate = () => {
    let error = false;
    let selectedStartDate = moment(startDate).format("MM/DD/YYYY");
    let selectedEndDate = moment(endDate).format("MM/DD/YYYY");

    if (calandarData?.length > 0) {
      for (var item = 0; item <= calandarData?.length; item++) {
        if (
          (moment(selectedStartDate).isBetween(
            moment(calandarData[item]?.startDate).format("MM/DD/YYYY"),
            moment(calandarData[item]?.endDate).format("MM/DD/YYYY"),
            undefined,
            "[]"
          ) &&
            moment(selectedEndDate).isBetween(
              moment(calandarData[item]?.startDate).format("MM/DD/YYYY"),
              moment(calandarData[item]?.endDate).format("MM/DD/YYYY"),
              undefined,
              "[]"
            )) ||
          moment(
            moment(calandarData[item]?.startDate).format("MM/DD/YYYY")
          ).isBetween(selectedStartDate, selectedEndDate, undefined, "[]") ||
          moment(
            moment(calandarData[item]?.endDate).format("MM/DD/YYYY")
          ).isBetween(selectedStartDate, selectedEndDate, undefined, "[]")
        ) {
          // alert("truestatic");
          error = true;
          break;
        }
      }
      // calandarData?.map((item) => {

      // });
      return error;
    } else {
      return error;
    }
  };
  const validateDates = () => {
    const index = calandarData.findIndex(
      (i) =>
        i.startDate === moment(startDate).format("YYYY-MM-DD") &&
        i.endDate === moment(endDate).format("YYYY-MM-DD")
    );
    if (index >= 0) return false;

    return calandarData?.some((elem) => {
      return !(
        moment(endDate)
          .endOf("days")
          .diff(moment(elem.startDate).startOf("days")) < 0 ||
        moment(startDate)
          .startOf("days")
          .diff(moment(elem.endDate).endOf("days")) > 0
      );
    });
  };
  // min booking hr check
  const minHrBookingChkForCal = () => {
    let start_time = moment(calendarStartTime, "hh:mm a").format("hh:mm a");
    let end_time = moment(calendarEndTime, "hh:mm a").format("hh:mm a");
    var duration = moment.duration(
      moment(start_time, "hh:mm a").diff(moment(end_time, "hh:mm a"))
    );
    var hours = parseInt(duration.asHours()); //duration in hours
    // var minutes = parseInt(duration.asMinutes()) % 60; //duration in minutes
    var check = Math.abs(hours) < minBookingHr ? true : false;

    return check;
  };
  // Add calander
  const onCalendarAdd = () => {
    let error = false;
    if (calendarStartTime === "" || calendarEndTime === "") error = true;

    if (validateDates()) {
      error = true;
      toast.error(t("dates_are_already_taken"));
    }

    if (
      calendarStartTime !== "" &&
      calendarEndTime !== "" &&
      (startGreaterThanEndTime(calendarStartTime, calendarEndTime) ||
        timeFormat(calendarStartTime) === timeFormat(calendarEndTime))
    ) {
      setCalanderValidate(true);
      error = true;
    }
    if (timeFormat(calendarStartTime) === timeFormat(calendarEndTime)) {
      return;
    }

    if (minHrBookingChkForCal()) {
      setCalendarStartTime("");
      setCalendarEndTime("");
      toast.error(`${t("minimum_booking_is")} ${minBookingHr} ${t("hours")}.`);
      return;
    }

    if (error) return;

    const index = calandarData.findIndex(
      (i) =>
        i.startDate === moment(startDate).format("YYYY-MM-DD") &&
        i.endDate === moment(endDate).format("YYYY-MM-DD")
    );
    if (index >= 0) {
      if (!validateTimeForCalendar(calandarData[index])) {
        toast.error(
          t("this_slot_is_already_taken,_please_select_different_slot.")
        );
        setCalendarStartTime("");
        setCalendarEndTime("");
        return;
      }
    }
    if (calandarData.length > 0) {
      if (index !== -1) {
        let dateFormat = `${timeFormat(calendarStartTime)} - ${timeFormat(
          calendarEndTime
        )}`;

        let data = {
          // startDate: moment(startDate).format("MMMM DD, YYYY"),
          // endDate: moment(endDate).format("MMMM DD, YYYY"),
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          slots: [...calandarData[index].slots, dateFormat],
        };
        calandarData[index] = data;
        setCalandarData(calandarData);
        setCalendarStartTime("");
        setCalendarEndTime("");
        setCalanderValidate(false);
      } else {
        let dateFormat = `${timeFormat(calendarStartTime)} - ${timeFormat(
          calendarEndTime
        )}`;
        let data = {
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          slots: [dateFormat],
        };
        setCalandarData([...calandarData, data]);
        setCalendarStartTime("");
        setCalendarEndTime("");
        setCalanderValidate(false);
      }
    } else {
      let dateFormat = `${timeFormat(calendarStartTime)} - ${timeFormat(
        calendarEndTime
      )}`;
      let data = {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        slots: [dateFormat],
      };
      setCalandarData([...calandarData, data]);
      setCalendarStartTime("");
      setCalendarEndTime("");
      setCalanderValidate(false);
      // setExcludeDateIntervals([{ start: data.startDate, end: data.endDate }]);
    }
  };
  // Deleting date in Calendar
  const onCalendarDelete = (index) => {
    const array = [...calandarData];
    if (index !== -1) {
      array.splice(index, 1);
      setCalandarData(array);
    }
  };
  // Deleting time from particular date in calendar
  const onCalendarDeleteTimeSlots = (indexArray, indexSlots) => {
    const array = [...calandarData];
    if (indexSlots !== -1) {
      array[indexArray].slots.splice(indexSlots, 1);
      if (array[indexArray].slots.length <= 0) {
        array.splice(indexArray, 1);
      }
      setCalandarData(array);
    }
  };

  // Format data for weekend
  const formatDataFun = () => {
    let newData = [];
    Object.keys(onlyWeekend).map((day) => {
      onlyWeekend[day].map((slotItem) => {
        let time = slotItem.split("-");
        let start_time = moment(time[0], ["h:mm A"]).format("HH:mm"); // previous Start Time
        let end_time = moment(time[1], ["h:mm A"]).format("HH:mm");
        let slots = [
          {
            end_time: end_time,
            start_time: start_time,
          },
        ];

        if (newData.find((item) => item.day === day)) {
          let updated = newData.find((item) => item.day === day);
          let newFilterData = newData.filter((item) => item.day !== day);
          updated.slots.push(...slots);
          newData = [...newFilterData, updated];
        } else {
          newData.push({
            day: day,
            slots: slots,
          });
        }
      });
    });

    return newData;
  };
  // Format data for days
  const formatDataForDays = () => {
    let newData = [];
    Object.keys(onlySelectedDays).map((day) => {
      onlySelectedDays[day].map((slotItem) => {
        let time = slotItem.split("-");
        let start_time = moment(time[0], ["h:mm A"]).format("HH:mm"); // previous Start Time
        let end_time = moment(time[1], ["h:mm A"]).format("HH:mm");
        let slots = [
          {
            end_time: end_time,
            start_time: start_time,
          },
        ];

        if (newData.find((item) => item.day === day)) {
          let updated = newData.find((item) => item.day === day);
          let newFilterData = newData.filter((item) => item.day !== day);
          updated.slots.push(...slots);
          newData = [...newFilterData, updated];
        } else {
          newData.push({
            day: day,
            slots: slots,
          });
        }
      });
    });

    return newData;
  };

  // Format data for calander
  const formatDataFunForCalander = (value) => {
    let newData = [];
    value.map((day) => {
      day.slots.map((slotItem) => {
        let time = slotItem.split("-");
        let start_time = moment(time[0], ["h:mm A"]).format("HH:mm"); // previous Start Time
        let end_time = moment(time[1], ["h:mm A"]).format("HH:mm");
        let slots = [
          {
            end_time: end_time,
            start_time: start_time,
          },
        ];

        if (
          newData.find(
            (item) =>
              item.start_date === day.startDate && item.end_date === day.endDate
          )
        ) {
          let updated = newData.find(
            (item) =>
              item.start_date === day.startDate && item.end_date === day.endDate
          );
          let newFilterData = newData.filter(
            (item) =>
              item.start_date !== day.startDate && item.end_date !== day.endDate
          );
          updated.slots.push(...slots);
          newData = [...newFilterData, updated];
        } else {
          newData.push({
            start_date: day.startDate,
            end_date: day.endDate,
            slots: slots,
          });
        }
      });
    });

    return newData;
  };

  // Main Handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();

    if (radioTabs === "everyday") {
      setSubmitted(true);
      let error = false;
      if (cancelationPolicy === 0) error = true;
      if (error) return;

      const finalData = {
        cpolicy_id: cancelationPolicy,
        formSerial: 3,
        handler: handler,
        // "handler": {
        //   "quickspot_id": handler?.quickspot_id?.quickspot_id,
        //   "property_id": handler?.property_id?.property_id
        // },
        operation: {
          everyday: true,
        },
      };
      setLoader(true);
      // Add list api call
      AddListSpotApiCall(finalData)
        .then((response) => {
          if (response.status === 200) {
            setLoader(false);
            setActiveListSpot(4);
            setCompletedStep(4);
            window.scrollTo(0, 0);
          }
          if (response.code === 400) {
            setLoader(false);
            toast.error(response.message);
          }
          if (response.status === 422) {
            setLoader(false);
            toast.error(response.message);
          }
        })
        .catch((error) => {
          createLogs({
            url: "/frontend/edit-list-spot?step=3",
            payload: JSON.stringify({
              error,
              values: finalData,
              headers: authTokenHeader(),
            }),
          });
        });
    }

    if (radioTabs === "weekend") {
      setSubmitted(true);
      let error = false;
      if (cancelationPolicy === 0 || check.length <= 0) error = true;
      if (check.includes("saturday") && onlyWeekend.saturday.length <= 0) {
        error = true;
      }
      if (check.includes("sunday") && onlyWeekend.sunday.length <= 0) {
        error = true;
      }
      if (error) return;

      // let slots = []
      // let weekdays = formatDataFun()

      const finalData = {
        cpolicy_id: cancelationPolicy,
        formSerial: 3,
        handler: handler,
        operation: {
          weekdays: formatDataFun(),
        },
      };

      setLoader(true);
      // Add list api call
      AddListSpotApiCall(finalData).then((response) => {
        if (response.status === 200) {
          setLoader(false);
          setActiveListSpot(4);
          setCompletedStep(4);
        }
        if (response.code === 400) {
          setLoader(false);
          toast.error(response.message);
        }
        if (response.status === 422) {
          setLoader(false);
          toast.error(response.message);
        }
      });
    }

    if (radioTabs === "selectedDays") {
      setSubmitted(true);
      setSubmittedSlecetedDays(true);
      let error = false;
      if (cancelationPolicy === 0 || checkForDays.length <= 0) error = true;

      if (
        checkForDays.includes("monday") &&
        onlySelectedDays.monday.length <= 0
      )
        error = true;
      if (
        checkForDays.includes("tuesday") &&
        onlySelectedDays.tuesday.length <= 0
      )
        error = true;
      if (
        checkForDays.includes("wednesday") &&
        onlySelectedDays.wednesday.length <= 0
      )
        error = true;
      if (
        checkForDays.includes("thursday") &&
        onlySelectedDays.thursday.length <= 0
      )
        error = true;
      if (
        checkForDays.includes("friday") &&
        onlySelectedDays.friday.length <= 0
      )
        error = true;
      if (
        checkForDays.includes("saturday") &&
        onlySelectedDays.saturday.length <= 0
      )
        error = true;
      if (
        checkForDays.includes("sunday") &&
        onlySelectedDays.sunday.length <= 0
      )
        error = true;

      if (error) return;

      const finalData = {
        cpolicy_id: cancelationPolicy,
        formSerial: 3,
        handler: handler,
        operation: {
          weekdays: formatDataForDays(),
        },
      };
      setLoader(true);

      // Add list api call
      AddListSpotApiCall(finalData).then((response) => {
        if (response.status === 200) {
          setLoader(false);
          setActiveListSpot(4);
          setCompletedStep(4);
        }
        if (response.code === 400) {
          setLoader(false);
          toast.error(response.message);
        }
        if (response.status === 422) {
          setLoader(false);
          toast.error(response.message);
        }
      });
    }

    if (radioTabs === "calandar") {
      setSubmitted(true);
      setSubmittedCalendar(true);
      let error = false;
      if (cancelationPolicy === 0 || calandarData.length <= 0) error = true;
      if (error) return;

      // setLoader(true);
      const finalData = {
        cpolicy_id: cancelationPolicy,
        formSerial: 3,
        handler: handler,
        operation: {
          calendar: formatDataFunForCalander(calandarData),
        },
      };

      // Add list api call
      AddListSpotApiCall(finalData).then((response) => {
        if (response.status === 200) {
          setLoader(false);
          setActiveListSpot(4);
          setCompletedStep(4);
        }
        if (response.code === 400) {
          setLoader(false);
          toast.error(response.message);
        }
        if (response.status === 422) {
          setLoader(false);
          toast.error(response.message);
        }
      });
    }
  };

  return (
    <>
      {loader && <ApiLoader />}
      <h3 className="fw-semibold mb-1">{t("spot_operation_hours")}</h3>

      <p className="text-default fs-13">
        {t(
          "operating_hours_are_the_days_and_hours_of_the_week_that_your_space_is_open_to_host_bookings"
        )}
      </p>
      <ul className="p-0 list-unstyled mt-3 mb-4 ow-cols-4 row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-2  g-0 d-flex flex-wrap border-bottom pb-md-4 pb-sm-3 ">
        <li>
          <div className="form-check mt-0">
            <label className="form-check-label text-default">
              <input
                className="form-check-input me-2"
                type="radio"
                name="radio"
                id="radio"
                checked={radioTabs === "everyday" ? true : false}
                onChange={() => setRadioTabs("everyday")}
              />
              {t("everyday")}
            </label>
          </div>
        </li>
        <li>
          <div className="form-check mt-0">
            <label className="form-check-label tstartTimeext-default">
              <input
                className="form-check-input me-2"
                type="radio"
                name="radio"
                id="radio"
                checked={radioTabs === "weekend" ? true : false}
                onChange={() => setRadioTabs("weekend")}
              />
              {t("only_weekend")}
            </label>
          </div>
        </li>
        <li>
          <div className="form-check mt-0">
            <label className="form-check-label text-default">
              <input
                className="form-check-input me-2"
                type="radio"
                name="radio"
                id="radio"
                checked={radioTabs === "selectedDays" ? true : false}
                onChange={() => setRadioTabs("selectedDays")}
              />
              {t("selected_days")}
            </label>
          </div>
        </li>
        <li>
          <div className="form-check mt-0">
            <label className="form-check-label text-default">
              <input
                className="form-check-input me-2"
                type="radio"
                name="radio"
                id="radio"
                checked={radioTabs === "calandar" ? true : false}
                onChange={() => setRadioTabs("calandar")}
              />
              {t("select_from_calendar")}
            </label>
          </div>
        </li>
      </ul>
      {/* 1 Everyday only Cancellation Policy will be displayed */}

      {/* 2 Weekend */}
      {radioTabs === "weekend" && (
        <div className="weektimepicker pb-md-4 pb-sm-3 mb-4">
          {Object.keys(onlyWeekend).map((day, index) => (
            <>
              <div key={index} className="row settime">
                <div className="col-md-3">
                  <div className="form-check mt-1 mb-0 d-flex align-items-center ">
                    <label className="form-check-label text-uppercase fs-15 fw-semibold lh-1">
                      <input
                        className="form-check-input mt-0 me-2"
                        type="checkbox"
                        checked={check.includes(day)}
                        onChange={(e) => handleChangeCheckbox(e, day, index)}
                      />
                      {t(day)}
                    </label>
                  </div>
                </div>
                <div className="col-md-9 ">
                  <div className="d-flex w-100">
                    <div className="form-group w-50" key={index}>
                      <DatePicker
                        selected={selectedStartTime(day)}
                        onChange={(date) => handleChangeStartTime(date, day)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption={t("time")}
                        dateFormat={dateFormat.TIME_FORMAT}
                        timeFormat={dateFormat.TIME_FORMAT}
                        placeholderText={t("start_time")}
                        className="form-select"
                        disabled={!check.includes(day)}
                        locale={LOCALE}
                      />
                    </div>
                    <div className="form-group w-50">
                      <DatePicker
                        selected={selectedEndTime(day)}
                        onChange={(date) => handleChangeEndTime(date, day)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption={t("time")}
                        dateFormat={dateFormat.TIME_FORMAT}
                        timeFormat={dateFormat.TIME_FORMAT}
                        placeholderText={t("end_time")}
                        className="form-select"
                        disabled={!check.includes(day)}
                        minTime={endTime.day}
                        locale={LOCALE}
                      />
                    </div>
                    <button
                      className="btn btn-outline-secondary "
                      onClick={() => addOnlyWeekendTime(day)}
                      disabled={!check.includes(day)}
                    >
                      {imagePath.plusicon}
                    </button>
                  </div>
                  {submitted &&
                    check.includes(day) &&
                    onlyWeekend[day].length === 0 && (
                      <p className="fs-12 text-danger pt-1">
                        {t("please_add_slots.")}
                      </p>
                    )}
                  {check.includes(day) &&
                    startTime[day] !== "" &&
                    endTime[day] !== "" &&
                    (startGreaterThanEndTime(startTime[day], endTime[day]) ||
                      timeFormat(startTime[day]) ===
                        timeFormat(endTime[day])) && (
                      <p className="fs-12 text-danger pt-1">
                        {t("please_enter_valid_time_slot.")}
                      </p>
                    )}
                  {/* {check.includes(day) && slotBooked[day] && <p className='fs-12 text-danger pt-1'>{t("this_slot_is_already_taken,_please_select_different_slot.")}</p>} */}
                  <div className="inputchips">
                    {onlyWeekend[day].map((date, ind) => (
                      <span
                        key={ind}
                        className="badge text-default fs-11 fw-medium border rounded"
                      >
                        {date}{" "}
                        <span onClick={() => removeTime(date, day, ind)}>
                          {imagePath.closeicon}
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ))}
          {submitted && check.length <= 0 && (
            <p className="fs-12 text-danger pt-1">
              {t("please_select_weekend")}
            </p>
          )}
        </div>
      )}

      {/* 3 Selected Days */}
      {radioTabs === "selectedDays" && (
        <>
          <div className="weektimepicker pb-md-4 pb-sm-3 mb-4">
            {
              // weekendDays.map((day, index) => (
              Object.keys(onlySelectedDays).map((day, index) => (
                <>
                  <div key={index} className="row settime">
                    <div className="col-md-3">
                      <div className="form-check mt-1 mb-0 d-flex align-items-center ">
                        <label className="form-check-label text-uppercase fs-15 fw-semibold lh-1">
                          <input
                            className="form-check-input me-2 mt-0 "
                            type="checkbox"
                            checked={checkForDays.includes(day)}
                            onChange={(e) =>
                              handleChangeCheckboxForDays(e, day, index)
                            }
                          />

                          {t(day)}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-9 ">
                      <div className="d-flex w-100">
                        <div className="form-group w-50" key={index}>
                          <DatePicker
                            selected={selectedStartTimeForDays(day)}
                            onChange={(date) =>
                              handleChangeStartTimeForDays(date, day)
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption={t("time")}
                            dateFormat={dateFormat.TIME_FORMAT}
                            timeFormat={dateFormat.TIME_FORMAT}
                            placeholderText={t("start_time")}
                            className="form-select"
                            disabled={!checkForDays.includes(day)}
                            locale={LOCALE}
                          />
                        </div>
                        <div className="form-group w-50">
                          <DatePicker
                            selected={selectedEndTimeForDays(day)}
                            onChange={(date) =>
                              handleChangeEndTimeForDays(date, day)
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption={t("time")}
                            dateFormat={dateFormat.TIME_FORMAT}
                            timeFormat={dateFormat.TIME_FORMAT}
                            placeholderText={t("end_time")}
                            className="form-select"
                            disabled={!checkForDays.includes(day)}
                            locale={LOCALE}
                          />
                        </div>
                        <button
                          className="btn btn-outline-secondary "
                          onClick={() => addOnlySelectedDaysTime(index, day)}
                        >
                          {imagePath.plusicon}
                        </button>
                      </div>

                      {submittedSlecetedDays &&
                        checkForDays.includes(day) &&
                        onlySelectedDays[day].length === 0 && (
                          <p className="fs-12 text-danger pt-1">
                            {t("please_add_slots.")}
                          </p>
                        )}
                      {checkForDays.includes(day) &&
                        startTimeForDays[day] !== "" &&
                        endTimeForDays[day] !== "" &&
                        (startGreaterThanEndTime(
                          startTimeForDays[day],
                          endTimeForDays[day]
                        ) ||
                          timeFormat(startTimeForDays[day]) ===
                            timeFormat(endTimeForDays[day])) && (
                          <p className="fs-12 text-danger pt-1">
                            {t("please_enter_valid_time_slot.")}
                          </p>
                        )}
                      {/* {checkForDays.includes(day) && slotBookedForDays[day] && <p className='fs-12 text-danger pt-1'>{t("this_slot_is_already_taken,_please_select_different_slot.")}</p>} */}
                      <div className="inputchips">
                        {onlySelectedDays[day].map((date, ind) => (
                          <span
                            key={ind}
                            className="badge text-default fs-11 fw-medium border rounded"
                          >
                            {date}{" "}
                            <span
                              onClick={() => removeTimeForDays(date, day, ind)}
                            >
                              {imagePath.closeicon}
                            </span>
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ))
            }
            {submittedSlecetedDays && checkForDays.length <= 0 && (
              <p className="fs-12 text-danger pt-1">
                {t("please_select_days")}
              </p>
            )}
          </div>
        </>
      )}

      {/* 4 Calandar */}
      {radioTabs === "calandar" && (
        <>
          <div className="calendarbox bg-white">
            <div className="row g-0">
              <div className="col-md-7">
                <div className="calendarui mb-3">
                  <DatePicker
                    // excludeDateIntervals={excludeDateIntervals}
                    selected={startDate}
                    onChange={onChangeCalendar}
                    startDate={startDate}
                    endDate={showEndDate}
                    minDate={moment().add(1, "days").toDate()}
                    selectsRange
                    inline
                    locale={LOCALE}
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="rightbox">
                  <div className="row align-items-end">
                    <div className="col-7">
                      <p className="fw-normal fs-15 mb-3 pb-1 text-start text-default-400">
                        {t("from")}
                        <strong className="d-block text-default">
                          {moment(startDate).format("MMMM DD, YYYY")}
                        </strong>
                      </p>
                      <p className="fw-normal fs-15 mb-0 text-start text-default-400 ">
                        {t("to")}
                        <strong className="d-block text-default ">
                          {moment(endDate).format("MMMM DD, YYYY")}
                        </strong>
                      </p>
                    </div>
                    <div className="col-5 text-end">
                      {imagePath.celandarClockIcon}
                    </div>
                  </div>
                  <div className="d-flex flex-column w-100 mt-4">
                    <div className="form-group w-100">
                      <DatePicker
                        selected={calendarStartTime}
                        onChange={(date) => setCalendarStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        placeholderText={t("start_time")}
                        timeCaption={t("time")}
                        dateFormat={dateFormat.TIME_FORMAT}
                        timeFormat={dateFormat.TIME_FORMAT}
                        className="form-control customtime"
                        locale={LOCALE}
                      />
                    </div>
                    <div className="form-group w-100">
                      <DatePicker
                        selected={calendarEndTime}
                        onChange={(date) => setCalendarEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        placeholderText={t("end_time")}
                        timeCaption={t("time")}
                        dateFormat={dateFormat.TIME_FORMAT}
                        timeFormat={dateFormat.TIME_FORMAT}
                        className="form-control customtime"
                        locale={LOCALE}
                      />
                    </div>
                    <div className="form-group w-100">
                      <button
                        className="btn btn-outline-primary w-100"
                        onClick={onCalendarAdd}
                      >
                        {imagePath.plusicon}
                      </button>
                      {calanderValidate &&
                        (startGreaterThanEndTime(
                          calendarStartTime,
                          calendarEndTime
                        ) ||
                          timeFormat(calendarStartTime) ===
                            timeFormat(calendarEndTime)) && (
                          <p className="fs-12 text-danger pt-1">
                            {t("please_enter_valid_time_slot.")}
                          </p>
                        )}
                      {/* {  && <p className='fs-12 text-danger pt-1'>{t("please_enter_valid_time_slot.")}</p>} */}
                      {/* {calendarEndTime==="" && calendarEndTime==="" && <p className='fs-12 text-danger pt-1'>{ t("please_select_days")}</p>} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="selectedatetime border-bottom mt-3 mt-md-4 mt-sm-3">
            <h3 className="fw-semibold  mb-0">{t("selected_date/time")}</h3>
            {calandarData.length <= 0 ? (
              <p>{t("no_record_found.")}</p>
            ) : (
              calandarData.map((i, index) => (
                <>
                  <div className="datebox border" key={index}>
                    <div className="row align-items-center">
                      <div className="col-md-11">
                        <h5 className=" fs-15 mb-0 text-secondary fw-semibold ">
                          {moment(i.startDate).format("MMMM DD, YYYY")} -{" "}
                          {moment(i.endDate).format("MMMM DD, YYYY")}
                        </h5>
                        <div className="inputchips">
                          {calandarData[index].slots.map((slots, ind) => (
                            <span
                              className="badge text-default fs-11 fw-medium border rounded"
                              key={ind}
                            >
                              {slots}{" "}
                              <span
                                onClick={() =>
                                  onCalendarDeleteTimeSlots(index, ind)
                                }
                              >
                                {imagePath.closeicon}
                              </span>
                            </span>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-1 text-center">
                        <span
                          className="deleteicon mb-2 d-inline-block"
                          onClick={() => onCalendarDelete(index)}
                        >
                          {imagePath.deleteIcon}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ))
            )}
            {submittedCalendar && calandarData.length <= 0 && (
              <p className="fs-12 text-danger pt-1">
                {t("please_select_date_&_time.")}
              </p>
            )}
          </div>
        </>
      )}

      {/* Cancelation Policy Common for aull four 4 */}
      <h3 className="fw-semibold  mb-2">{t("cancellation_policy")}</h3>
      <p className="fw-normal fs-15 text-default-400 mb-3 pb-1">
        {t("cancellation_policy_description")}
      </p>
      {submitted && cancelationPolicy === 0 && (
        <p className="fs-11 text-danger pt-1">
          {t("please_select_cancellation_policy.")}
        </p>
      )}

      {cancellationPolicy.map((value) => (
        <div className="">
          <div className="form-check d-flex align-items-center mb-2">
            <input
              className="form-check-input me-2"
              type="radio"
              name="policy"
              id={value.qs_lookup_id}
              checked={cancelationPolicy == value.qs_lookup_id}
              onChange={(e) => setCancelationPolicy(value.qs_lookup_id)}
            />
            <label
              className="form-check-label fs-18 text-secondary fw-semibold lh-lg"
              htmlFor={value.qs_lookup_id}
            >
              {value.qs_lookup_details.title}
            </label>
          </div>
          <p className="fs-13 text-default ps-4">
            <ReadMore>{value.qs_lookup_details.description}</ReadMore>

            {/* <a
              className="text-link fw-medium text-decoration-none text-nowrap "
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "See Less" : "See More"}
            </a> */}
          </p>
        </div>
      ))}

      {/* <div className="">
        <div className="form-check d-flex align-items-center mb-2">
          <input
            className="form-check-input me-2"
            type="radio"
            name="policy"
            value={2}
            checked={cancelationPolicy === 2}
            onChange={(e) => setCancelationPolicy(+e.currentTarget.value)}
          />
          <label
            className="form-check-label fs-18 text-secondary fw-semibold lh-lg"
            htmlFor="flexRadioDefault2"
          >
            Cancellation Policy 2
          </label>
        </div>
        <p className="fs-13 text-default ps-4">
          Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
          consectetur, adipisci velit. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam...
          <a
            className="text-link fw-medium text-decoration-none text-nowrap"
            href="#"
          >
            See More
          </a>
        </p>
      </div>
      <div className="">
        <div className="form-check d-flex align-items-center mb-2">
          <input
            className="form-check-input me-2"
            type="radio"
            name="policy"
            id="flexRadioDefault3"
            value={3}
            checked={cancelationPolicy === 3}
            onChange={(e) => setCancelationPolicy(+e.currentTarget.value)}
          />
          <label
            className="form-check-label fs-18 text-secondary fw-semibold lh-lg"
            htmlFor="flexRadioDefault3"
          >
            Cancellation Policy 3
          </label>
        </div>
        <p className="fs-13 text-default ps-4">
          Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
          consectetur, adipisci velit. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam...
          <a
            className="text-link fw-medium text-decoration-none text-nowrap"
            href="#"
          >
            See More
          </a>
        </p>
      </div>
      <div className="">
        <div className="form-check d-flex align-items-center mb-2">
          <input
            className="form-check-input me-2"
            type="radio"
            name="policy"
            id="flexRadioDefault4"
            value={4}
            checked={cancelationPolicy === 4}
            onChange={(e) => setCancelationPolicy(+e.currentTarget.value)}
          />
          <label
            className="form-check-label fs-18 text-secondary fw-semibold lh-lg"
            htmlFor="flexRadioDefault4"
          >
            Cancellation Policy 4
          </label>
        </div>
        <p className="fs-13 text-default ps-4">
          Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
          consectetur, adipisci velit. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam...
          <a
            className="text-link fw-medium text-decoration-none text-nowrap"
            href="#"
          >
            See More
          </a>
        </p>
      </div> */}

      <div className="row justify-content-between mt-5">
        <div className="col-md-3 col-6">
          <button
            type="button"
            className="btn btn-outline-secondary backbtn"
            onClick={() => {
              setActiveListSpot(2);
              setCompletedStep(2);
              window.scrollTo(0, 0);
            }}
          >
            {t("back")}
          </button>
        </div>
        <div className="col-md-3 col-6  text-end text-md-end">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            {t("next")}
          </button>
        </div>
      </div>
    </>
  );
}

export default OperatingHours;
