import React from 'react'




const ContentLoader = () => {
  return (
    
    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    
  )
}

export default ContentLoader