import React from "react";
import Footer from "../../../common/Footer/Footer";
import Header from "../../../common/Header/Header";

export default function NotFound() {
  return (
    <>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="mb-4 pb-2 fw-semibold text-default text-center mt-4">
              404 Page Not Found
            </h1>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
