import React, { useCallback, useEffect, useState } from "react";
import ApiLoader from "../../../common/ApiLoader";
import { t } from "i18next";
import { imagePath } from "../../../constants/imageUrl";
import ReactTooltip from "react-tooltip";
import DropBox from "./DropBox";
import { postImageOnApiCall } from "../../../_services/image.service";
import { toast } from "react-toastify";
import {
  AddListSpotApiCall,
  getListSpotApiCall,
} from "../../../_services/listSpot/listSpot.service";
import { authTokenHeader } from "../../../_services/auth-header";
import { createLogs } from "../../../_services/logs/logs.service";

export default function SpotPictures({
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleBlur,
  handleSubmit,
  handler,
  setActiveListSpot,
  setCompletedStep,
  completedStep,
}) {
  const [loader, setLoader] = useState(false);
  const [availableParking, setAvailableParking] = useState([]);
  const [images, setImages] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const handleCheck = (event) => {
    var permissions_array = [...availableParking];
    if (event.target.checked) {
      permissions_array = [...availableParking, event.target.value];
    } else {
      permissions_array.splice(availableParking.indexOf(event.target.value), 1);
    }
    setAvailableParking(permissions_array);
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    rejectedFiles.map(() => {
      setFileSizeError(true);
    });
    acceptedFiles.map((file, index) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (reader.readyState === 2) {
          setFileSizeError(false);
          //response of image upload will be stored in newImage

          setLoader(true);
          postImageOnApiCall(file).then((response) => {
            if (response.status === 200) {
              setLoader(false);

              setImages((images) => [
                ...new Set([...images, response?.data?.imagePath]),
              ]);
            }
            if (response.code === 400) {
              toast.error(response.message);
            }
            if (response.status === 422) {
              toast.error(response.message);
            }
          });
        }
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const removeImage = (index) => {
    setImages(images.filter((img, ind) => ind !== index));
  };

  const handleBack = () => {
    setActiveListSpot(1);
  };

  const handleNext = () => {
    let error = false;
    setSubmitted(true);
    let finalData = {
      formSerial: 2,
      handler: handler,
      spotpicture: values.spotpicture,
      availableParking: availableParking,
      images: images,
    };
    if (images?.length < 5) {
      error = true;
    }

    if (images?.length > 50) {
      error = true;
    }
    if (error) return;

    setLoader(true);
    AddListSpotApiCall(finalData)
      .then((response) => {
        if (response.status === 200) {
          setLoader(false);

          setCompletedStep(3);
          setActiveListSpot(3);
          if (completedStep === 2) {
            setCompletedStep(3);
          } else {
            setCompletedStep(completedStep);
          }

          window.scrollTo(0, 0);
        }
        if (response.code === 400) {
          toast.error(response.message);
        }
        if (response.status === 422) {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        createLogs({
          url: "/frontend/edit-list-spot?step=2",
          payload: JSON.stringify({
            error,
            values: finalData,
            headers: authTokenHeader(),
          }),
        });
      });
  };

  useEffect(() => {
    if (handler !== "") {
      // get all data
      setLoader(true);
      getListSpotApiCall(handler?.quickspot_id).then((response) => {
        if (response.status === 200) {
          setLoader(false);
          let data = response?.data?.data[0];
          let newData = data?.qs_addons?.spotpicture;
          if (data?.qs_addons !== null) {
            if (data?.qs_addons?.availableParking) {
              newData?.parkingOptions
                ? setAvailableParking(data?.qs_addons?.availableParking)
                : setAvailableParking([]);
            }
          }
          if (data?.qs_images?.images) {
            setImages(data?.qs_images?.images);
          }
        }
        if (response.status === 201) {
          toast.error(response.message);
        }
      });
    } else {
    }
  }, []);

  return (
    <>
      {loader && <ApiLoader />}
      <h3 className="fw-semibold mb-1">
        {t("security_of_spot")} <small className="text-danger fs-13"></small>{" "}
      </h3>
      {/* <pre>{JSON.stringify(values.spotpicture)}</pre>
      <pre>{JSON.stringify(errors.spotpicture)}</pre> */}
      <p>
        <div className="form-group mb-3 ">
          <div className="align-items-center w-100 justify-content-between d-inline-flex form-check form-switch m-0 p-0 mh-40">
            <label
              className="form-check-label fw-medium me-2 text-default"
              htmlFor="mapViewSwitchBtn"
            >
              {t("is_security_cameras_and_recording_devices_installed")}?
            </label>
            <input
              className="float-none form-check-input m-0"
              type="checkbox"
              id="mapViewSwitchBtn"
              checked={values.spotpicture.spotSecurity}
              onChange={(e) => {
                setFieldValue("spotpicture.spotSecurity", e.target.checked);
              }}
            />
          </div>
        </div>
      </p>
      <h3 className="fw-semibold mt-md-5 mt-sm-3 mb-1">
        {t("pet_friendly")} <small className="text-danger fs-13"></small>{" "}
      </h3>
      <p>
        <div className="form-group mb-3 ">
          <div className="align-items-center w-100 justify-content-between d-inline-flex form-check form-switch m-0 p-0 mh-40">
            <label
              className="form-check-label fw-medium me-2 text-default"
              htmlFor="mapViewSwitchBtn"
            >
              {t("is_pet_allowed_at_your_spot")}?
            </label>
            <input
              className="float-none form-check-input m-0"
              type="checkbox"
              id="mapViewSwitchBtn"
              checked={values.spotpicture.spotPetFriendly}
              onChange={(e) => {
                setFieldValue("spotpicture.spotPetFriendly", e.target.checked);
              }}
            />
          </div>
        </div>
      </p>
      <h3 className="fw-semibold  mt-md-5 mt-sm-3 mb-1">
        {t("parking_options")}
        <small className="text-danger fs-13"></small>{" "}
      </h3>
      <p>
        <div className="form-group mb-3 ">
          <div className="align-items-center w-100 justify-content-between d-inline-flex form-check form-switch m-0 p-0 mh-40">
            <label
              className="form-check-label fw-medium me-2 text-default"
              htmlFor="mapViewSwitchBtn"
            >
              {t("is_parking_available_at_your_spot")}?
            </label>
            <input
              className="float-none form-check-input m-0"
              type="checkbox"
              id="mapViewSwitchBtn"
              checked={values.spotpicture.parkingOptions}
              onChange={(e) =>
                setFieldValue("spotpicture.parkingOptions", e.target.checked)
              }
            />
          </div>
        </div>
      </p>
      <h6 className="fs-15 fw-semibold">
        {t("select_available_parking_facilities")}
      </h6>
      {values.spotpicture.parkingOptions === true &&
        availableParking?.length <= 0 && (
          <p className="fs-11 text-danger pt-1">
            {t("please_select_available_parking_facilities")}
          </p>
        )}
      <ul className="p-0 list-unstyled mt-3 mb-4 ow-cols-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-2 row-cols-1  g-0 d-flex flex-wrap disabled">
        <li>
          <div className="form-check mt-0">
            <input
              className="form-check-input "
              type="checkbox"
              id="flexCheckDefault"
              disabled={!values.spotpicture.parkingOptions}
              checked={
                availableParking?.includes(t("free_onsite_parking"))
                  ? true
                  : false
              }
              value={t("free_onsite_parking")}
              onChange={handleCheck}
            />
            <label
              className="form-check-label text-default fs-13 "
              htmlFor="flexCheckDefault"
            >
              {t("free_onsite_parking")}
            </label>
          </div>
        </li>
        <li>
          <div className="form-check mt-0">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault1"
              disabled={!values.spotpicture.parkingOptions}
              checked={availableParking?.includes(t("velvet")) ? true : false}
              value={t("velvet")}
              onChange={handleCheck}
            />
            <label
              className="form-check-label text-default fs-13 "
              htmlFor="flexCheckDefault1"
            >
              {t("velvet")}
            </label>
          </div>
        </li>
        <li>
          <div className="form-check mt-0">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault2"
              disabled={!values.spotpicture.parkingOptions}
              checked={
                availableParking?.includes(t("paid_street_parking"))
                  ? true
                  : false
              }
              value={t("paid_street_parking")}
              onChange={handleCheck}
            />
            <label
              className="form-check-label text-default fs-13 "
              htmlFor="flexCheckDefault2"
            >
              {t("paid_street_parking")}
            </label>
          </div>
        </li>
        <li>
          <div className="form-check mt-0">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault3"
              disabled={!values.spotpicture.parkingOptions}
              checked={
                availableParking?.includes(t("free_street_parking"))
                  ? true
                  : false
              }
              value={t("free_street_parking")}
              onChange={handleCheck}
            />
            <label
              className="form-check-label text-default fs-13 "
              htmlFor="flexCheckDefault3"
            >
              {t("free_street_parking")}
            </label>
          </div>
        </li>
        <li>
          <div className="form-check mt-0">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault4"
              disabled={!values.spotpicture.parkingOptions}
              checked={
                availableParking?.includes(t("nearby_parking")) ? true : false
              }
              value={t("nearby_parking")}
              onChange={handleCheck}
            />
            <label
              className="form-check-label text-default fs-13 "
              htmlFor="flexCheckDefault4"
            >
              {t("nearby_parking")}
            </label>
          </div>
        </li>
        <li>
          <div className="form-check mt-0">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault5"
              disabled={!values.spotpicture.parkingOptions}
              checked={
                availableParking?.includes(t("metered_street_parking"))
                  ? true
                  : false
              }
              value={t("metered_street_parking")}
              onChange={handleCheck}
            />
            <label
              className="form-check-label text-default"
              htmlFor="flexCheckDefault5"
            >
              {t("metered_street_parking")}
            </label>
          </div>
        </li>
      </ul>
      <div className="form-floating mb-3">
        <textarea
          name=""
          id=""
          rows="10"
          className="form-control"
          placeholder={t("additional_parking_instructions")}
          disabled={!values.spotpicture.parkingOptions}
          value={values.spotpicture.additionalParkingInst}
          onChange={
            (e) => {
              setFieldValue(
                "spotpicture.additionalParkingInst",
                e.target.value
              );
            }
            // setSpotPicture({
            //   ...spotpicture,
            //   additionalParkingInst: e.target.value,
            // })
          }
        ></textarea>
        <label>{t("additional_parking_instructions")}</label>
        {errors.spotpicture?.additionalParkingInst && (
          <p className="fs-11 text-danger pt-1">
            {errors.spotpicture?.additionalParkingInst}
          </p>
        )}
      </div>
      <h3 className="fw-semibold mt-md-5 mt-sm-3 mb-1">
        {t("who's_allowed_in_the_house?")}
      </h3>
      <p className="fs-15 mb-3 pb-1 text-default-400">
        {t("include_the_age_group_who_are_allowed")}
      </p>
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating">
            <select
              className="form-select pt-2 h-50"
              value={values.spotpicture.ageGroup}
              onChange={(e) => {
                setFieldValue("spotpicture.ageGroup", e.target.value);
              }}
            >
              <option value="selected">{t("age_group")}</option>

              <option value={t("age_group_above_18")}>
                {t("age_group_above_18")}
              </option>
              <option value={t("all_age_group")}>{t("all_age_group")}</option>
            </select>
            {/* <label>Age Group *</label> */}
            {values.spotpicture.ageGroup === "selected" && (
              <p className="fs-11 text-danger pt-1">
                {t("please_enter_age_group.")}
              </p>
            )}
          </div>
        </div>
      </div>
      <h3 className="fw-semibold mt-md-5 mt-sm-3 mb-1">
        {t("spot_pictures")}

        <a
          className="text-default-400 ms-1"
          data-toggle="tooltip"
          data-tip
          data-for="imageTip"
        >
          {imagePath.info}
        </a>
      </h3>
      <p className="fs-15 mb-3 pb-1 text-default-400">
        {t("add_minimum_5_high_quality_images")}
      </p>
      <ReactTooltip
        id="imageTip"
        place="top"
        effect="solid"
        className="customtooltip"
      >
        <h6 className="fs-14 text-white fw-semibold m-0 mb-1">
          {t("photo_requirements")}
        </h6>
        <ul className="p-0 p-0 m-0 fs-13 text-default-400 list-unstyled ">
          <li className="position-relative">
            {t("high_resolution")} ({t("at_least_1000_pixels_wide")})
          </li>
          <li className="position-relative">
            {t("horizontal_orientation")} ({t("no_vertical_photos")})
          </li>
          <li className="position-relative">
            {t("color_photos")}({t("no_black_and_white")})
          </li>
          <li className="position-relative">
            {t("no_collage_screenshot_or_watermark")}
          </li>
          <li className="position-relative ">
            {t("maximum_file_size_is_3_mb")}
          </li>
        </ul>
      </ReactTooltip>
      {/* Drag & Drop */}
      <DropBox onDrop={onDrop} />
      <div className="dropboximgbox mb-4 ">
        {images.map((image, index) => {
          return (
            <div key={image}>
              <div
                onClick={() => removeImage(index)}
                className="closeicon rounded-circle text-center d-flex align-items-center justify-content-center text-white position-absolute"
              >
                {imagePath.closeicon}
              </div>
              <img alt="" src={`${process.env.REACT_APP_API_URL}${image}`} />
            </div>
          );
        })}
      </div>
      {submitted && images?.length < 5 && (
        <p className="fs-11 text-danger pt-1">
          {t("please_add_minimum_five_images")}
        </p>
      )}
      {fileSizeError && (
        <p className="fs-11 text-danger">
          {t("image_size_cannot_be_greater_than_3MB.")}
        </p>
      )}

      {submitted && images?.length > 50 && (
        <p className="fs-11 text-danger pt-1">{t("Máximo de 50 fotos")}</p>
      )}

      <div className="row justify-content-between ">
        <div className="col-md-3 col-6">
          <button
            type="button"
            className="btn btn-outline-secondary backbtn "
            onClick={handleBack}
          >
            {t("back")}
          </button>
        </div>
        <div className="col-md-3 col-6  text-end text-md-end">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleNext}
          >
            {t("next")}
          </button>
        </div>
      </div>
    </>
  );
}
