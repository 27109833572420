const messages = {
  //common
  //days
  monday: "SEGUNDA",
  tuesday: "TERÇA",
  wednesday: "QUARTA",
  thursday: "QUINTA",
  friday: "SEXTA",
  saturday: "SÁBADO",
  sunday: "DOMINGO",
  //months
  january: "Janeiro",
  february: "Fevereiro",
  march: "Março",
  april: "Abril",
  may: "Maio",
  june: "Junho",
  july: "Julho",
  august: "Agosto",
  september: "Setembro",
  october: "Outubro",
  november: "Novembro",
  december: "Dezembro",
  to: "Para",
  from: "De",
  time: "Horário",
  start_time: "Início",
  end_time: "Fim",
  cancel: "Cancelar",
  //common in pages
  sign_up: "Cadastre-se",
  email_address: "Email",
  login: "Entrar",
  //login page
  welcome_back: "Bem-vindo de volta",
  login_to_quickspot: "Entrar no Quickspot",
  password: "Senha",
  remember_me: "Lembrar dados",
  "forgot_password?": "Esqueceu a senha?",
  login_button: "Entrar",
  or_login_using: "Ou entrar usando",
  "don't_have_an_account?": "Não tem Conta?",
  //signup page
  join_quickspot: "Junte-se ao Quickspot",
  confirm_password: "Confirmar Senha",
  referral_code: "Código de indicação",
  enter_the_following_details: "Preencha as seguintes informações",
  "i_agree_to_quickspot's": "Eu concordo com",
  service_agreement: "os Termos de serviços",
  and: "e",
  "i_do_not_want_to_receive_marketing_emails_from_quickspot.":
    "Eu não quero receber email marketing do Quickspot",
  "quickspots_will_send_you_deals,_inspiration_and_marketing_emails._you_can_opt_out_at_anytime_from_you_account_setting":
    "O Quickspot irá te enviar oportunidades, inspirações e email de marketing. Você poderá se descadastrar a qualquer momento nas configurações da sua conta ",
  or_sign_up_using: "Ou criar conta usando",
  "already_have_an_account?": "Já tem uma conta?",
  //Forgot Password
  forgot_password: "Esqueci minha senha",
  "enter_your_email_address.": "Preencha seu email",

  enter_your_email_address: "Digite se email",

  send: "Enviar",
  back_to: "Voltar",
  //header
  home: "Início",
  inbox: "Mensagens",
  my_bookings: "Minhas reservas",
  my_listings: "Meus spots",
  my_wishlist: "Favoritos",
  profile: "Perfil",
  "refer_&_earn": "Indique e ganhe",
  add_spot: "Adicionar Spot",
  faq_and_help_center: "FAQ & Central de Ajuda",
  logout: "Sair",
  //footer
  quick_links: "Links Rápidos",
  list_your_spot: "Cadastre seu Spot",
  blog: "Blog",
  contact: "Contato",
  support: "Suporte",
  "faq_&_help_center": "FAQ & Central de Ajuda",
  legal: "Jurídico",
  "terms_&_conditions": "Termos & Condições",
  privacy_policy: "Política de Privacidade",
  cancellation_policies: "Políticas de Cancelamento",
  refund_policies: "Políticas de Reembolso",
  social_links: "Mídias Sociais",
  //home page
  find_your_ideal_spot: "Alugue um mundo, por um minuto",
  find_your_ideal_spot_sub_heading:
    "Encontre os cenários ideais para suas fotos, vídeos e conteúdo",
  spot_type: "Tipo de Spot",
  select_category: "Selecionar Categorias",
  spot_style: "Estilo do Spot",
  select_style: "Selecionar Estilo",
  "where?": "Onde?",
  "when?": "Quando?",
  select: "Selecionar",
  search: "Buscar",
  search_location: "Local do spot",
  all_cities: "todas as cidades",
  all_categories: "Todas as categorias",
  //Main Spots section
  main_spots: "Encontre o cenário que você idealiza",
  view_all_categories: "Ver todas as Categorias",
  //How It Works? section
  "how_it_works?": "Como Funciona?",
  how_it_works_subheading: "3 passos para reservar o seu spot",
  select_category: "Busque",
  select_category_subheading:
    "Escolha o spot por estilo, tipo, comodidades e muito mais.",
  search_spot: "Escolha",
  search_spot_subheading:
    "Faça sua reserva por hora, de forma rápida e segura.",
  book_spot: "Reserve",
  book_spot_subheading:
    "Confirmamos sua reserva e conectamos você com os proprietários dos spots.",
  //why_quickspot section
  "why_quickspot?": "Por que Quickspot?",
  why_quickspot_subheading:
    "Ajudamos você a encontrar spots únicos para reservar por hora, de forma fácil, segura e transparente.",
  many_types_of_places_to_shoot: "Diversos tipos e estilos de ambientes",
  many_types_of_places_to_shoot_subheading:
    "Não conte com a sorte para encontrar o cenário ideal para seu conteúdo áudio-visual. Descubra spots únicos que combinam com você ou a sua marca.",
  simple_safe_and_accessible: "Simples, seguro e acessível",
  simple_safe_and_accessible_subheading:
    "Plataforma descomplicada, pagamento seguro e gestão transparente para você reservar sem stress.",
  select_the_period_you_need_the_spot: "Reserve spots por hora",
  select_the_period_you_need_the_spot_subheading:
    "spots únicos disponíveis para alugar pelo tempo que você precisar. Ajudamos você a tirar suas ideias do papel, com um orçamento que cabe no seu bolso.",
  know_exactly_what_waits_for_you: "Saiba exatamente o que te espera",
  know_exactly_what_waits_for_you_subheading:
    "Veja detalhes essenciais para sua experiência. Explore as comodidades, equipamentos adicionais, os comentários e mais!",
  //review section
  reviews: "Depoimentos dos criadores",
  what_our_clients_say_about_us:
    "O que nossos clientes falam sobre o Quickspot",
  looker_since: "criador desde ",
  //become a spotter section
  "become_a_spotter!": "Seja um anfitrião",
  become_a_spotter_subheading:
    "Crie anúncios dos seus spots em minutos e sem custo. Aproveite ao máximo as suas propriedades, sem que sua nova renda atrapalhe a sua vida ou o seu negócio.",
  join_now: "Vamos lá!",
  //city section
  we_have_unlocked_places_all_over_brazil:
    "Encontre o spot perfeito em sua cidade",
  view_all_the_cities: "Visualizar Cidades",
  //Message inbox page
  messages: "Mensagens",
  search_messages: "Buscar Mensagens",
  all_messages: "Todas as Mensagens",
  "write_something...": "Escreva aqui…",
  //reset Password
  reset_password: "Resetar Senha",
  enter_new_password: "Insira sua Nova Senha",
  new_password: "Nova Senha",
  confirm_new_password: "Confirmar Nova Senha",
  submit: "Enviar",
  //complete profile
  complete_your_profile: "Complete seu Perfil",
  this_helps_building_trust_between_guests_and_hosts:
    "Isso ajuda a construir confiança entre anfitriões e criadores",
  first_name: "Nome",
  last_name: "Sobrenome",
  phone_number: "Telefone",
  we_will_only_use_phone_number_for_booking_updates:
    "O Quickspot usa seu telefone somente para atualizações de reservas",
  cpf: "CPF",
  cnpj: "CNPJ",
  date_of_birth: "Data de Nascimento",
  company_name: "Nome da Companhia",
  how_did_you_hear_about_us: "Como você ouviu falar do Quickspot",
  linkedin: "Facebook ou instagram",
  friend: "Amigo/Amiga",
  referral_from_friend_or_family: "Indicação de amigo ou família",
  google: "Google ou busca em web",
  complete_profile: "Complete seu Perfil",
  upload_profile_picture: "Crie sua foto do perfil",
  supported_files: "Arquivos suportados",
  png: "PNG",
  jpg: "JPG",
  jpeg: "JPEG",
  //Contact us page
  contact_us: "Entre em contato",
  tell_us_how_can_we_help: "Como podemos te ajudar?",
  phone: "Telefone",
  email: "Email",
  if_you_have_any_query: "Se você tiver alguma dúvida",
  get_in_touch_with_us: "entre em contato com a gente",
  your_email_address: "Seu Email",
  your_name: "Seu Nome",
  subject: "Assunto",
  description: "Descrição",
  drag_and_drop_files_here_or: "Arraste e solte arquivos aqui ou",
  choose_files: "selecione arquivos",
  submit: "Enviar",
  //faq and help center page
  the_faqs: "PERGUNTAS FREQUENTES",
  help_center: "Central de Ajuda",
  everything_you_need_to_know_about_our_services:
    "Tudo que você precisa saber sobre os serviços e plataforma do Quickspot",
  search_topic: "Tópicos de Busca",
  lookers: "Criadores",
  spotters: "Anfitriões",
  looker: "criador",
  spotter: "anfitrião",
  everything_quickspot_lookers_need_to_know_about_how_to_use_our_marketplace_to_book_inspiring_spaces:
    "Tudo que precisa saber sobre como usar nossa plataforma para reservar spots",
  everything_quickspot_hosts_need_to_know_about_how_to_use_our_marketplace_to_book_inspiring_spaces:
    "Tudo que precisa saber sobre como usar nossa plataforma para rentabilizar os seus spots",
  help_topics_for_lookers: "Tópicos de ajuda para criadores",
  help_topics_for_spotters: "Tópicos de ajuda para anfitriões",
  frequently_asked_questions: "Perguntas frequentes sobre o Quickspot",
  "did_not_find_the_desired_answer?": "Não encontrou a resposta?",
  ask_your_own_question: "Entre em contato com nosso suporte",
  //list your spot header
  list_your_spot: "Cadastrar seu Spot",
  edit_your_spot: "Editar seu Spot",
  spot_details: "Informações Gerais",
  spot_pictures: "Fotos",
  operating_hours: "Horários",
  amenities: "Adicionais",
  //list your spot step-1
  basic_details: "Informações Gerais",
  spot_category: "Categoria",
  select_category: "Selecione Categorias",
  spot_style: "Estilo",
  select_style: "Selecione Estilo",
  spot_title: "Título",
  hourly_rate: "Preço por Hora",
  cleaning_fee: "Taxa de Limpeza",
  discount_percent_will_apply_on_hours_5_bookings_only:
    "Desconto aplicado em reservas acima de 5h",
  minimum_booking_hours: "Quantidade mínima de horas",
  maximum_guests_allowed: "Quantidade máxima de pessoas",
  maximum_guests_allowed_validation:
    "Quantidade máxima de pessoas(sem decimal)",
  spot_description: "Descrição do Spot",
  address: "Endereço",
  it_will_only_shared_with_guests_after_they_book_the_space:
    "O endereço só será compartilhado com o criador após a reserva do spot",
  country: "País",
  please_select_country: "Selecionar País",
  street_address: "Endereço",
  state: "Estado",
  address_2: "Bairro",
  city: "Cidade",
  postal_code: "CEP",
  rules_and_instructions: "Regras e Instruções",
  include_all_does_and_donts_of_the_house_rules:
    "Descreva o que é permitido ou proibido ao utilizar seu spot.",
  eg_no_smoking_on_the_building_outside_catering_is_allowed_etc:
    "Ex.: proibido fumar",
  back: "Voltar",
  next: "Próximo",
  //list your spot step-2
  security_of_spot: "Segurança do Spot",
  is_security_cameras_and_recording_devices_installed:
    "Há câmeras e/ou dispositivos de gravação instalados no spot",

  pet_friendly: "Animais de Estimação",

  is_pet_allowed_at_your_spot:
    "São permitidos animais de estimação no seu spot",
  parking_options: "Opções de Estacionamento",
  is_parking_available_at_your_spot:
    "Tem estacionamento disponível no seu spot",
  select_available_parking_facilities:
    "Selecione estacionamentos disponíveis no seu spot ",
  free_onsite_parking: "Gratuito no local",
  velvet: "Valet pago",
  paid_street_parking: "Estacionamento pago próximo",
  free_street_parking: "Gratuito na Rua",
  nearby_parking: "Opções Próximas ao Spot",
  metered_street_parking: "Vaga paga na rua",
  additional_parking_instructions: "Instruções adicionais para estacionamento",
  "who's_allowed_in_the_house?": "Faixas Etárias Permitidas",
  include_the_age_group_who_are_allowed:
    "Inclua aqui as faixas etárias permitidas",
  age_group: "Faixa Etária",
  age_group_above_18: "Acima de 18 anos",
  all_age_group: "Todas as idades",
  spot_pictures: "Fotos",
  photo_requirements: "Requisitos das fotos",
  high_resolution: "Alta resolução",
  at_least_1000_pixels_wide: "min. 1000 pixels de largura",
  horizontal_orientation: "Orientação horizontal",
  no_vertical_photos: "Proibido fotos na vertical",
  color_photos: "Cores das Fotos",
  no_black_and_white: "Proibido fotos preto e branco",
  no_collage_screenshot_or_watermark:
    "Proibido captura de tela, montagens e marca d'agua",
  add_minimum_5_high_quality_images:
    "Adicionar no mínimo 5 fotos de qualidade (máximo de 3 MB)",
  maximum_file_size_is_3_mb: "Tamanho máximo do arquivo é de 3 MB",
  //list your spot step-3
  spot_operation_hours: "Horário de funcionamento",
  operating_hours_are_the_days_and_hours_of_the_week_that_your_space_is_open_to_host_bookings:
    "As opções escolhidas definem as datas e horários que o seu spot ficará disponível para reserva. Atenção, pois estarão automáticamente aceitas as reservas solicitadas em horários vagos.",
  everyday: "Todos os dias",
  only_weekend: "Apenas finais de semana",
  selected_days: "Dias específicos da semana",
  select_from_calendar: "Selecionar no calendário",
  "selected_date/time": "Selecionar data/hora",
  no_record_found: "Nenhum registro encontrado",
  cancellation_policy: "Política de cancelamento",

  cancellation_policy_description:
    "Todas as reservas são 100% reembolsáveis se forem canceladas em até 24 horas após o pedido da reserva.",

  //booking details
  send_booking_request: "Enviar pedido de reserva",
  your_host_has_2_business_days_to_respond_to_your_booking_request: "",
  date_and_time: "Data & horário",
  edit_date_and_time: "Editar data & horário",
  included_amenities: "Adicionar comodidades",
  leave_note_to_your_host: "Deixar um recado para o anfitrião",
  note: "Recado",
  host_rules: "Regras do anfitrião",
  general_rules: "Regras Gerais",
  i_accept_and_understand_these_host_rules_disclosure:
    "Eu entendo e aceito as regras do anfitrião ",
  booking_details: "Detalhes da reserva ",
  make_payment: "Realizar pagamento",
  "you_must_accept_the_host_rules.": "Você deve aceitar as regras do Spot",
  service_fee_and_taxes: "Taxas de serviços e impostos",
  transaction_fees: "Taxa de processamento",
  total: "Total",
  //payment
  payments: "Pagamento",
  //validation messages
  "please_enter_email.": "Preencha seu email",
  "email_is_invalid.": "Email inválido",
  "please_enter_password.": "Preencha sua senha",
  "please_enter_confirm_password.": "Confirme sua senha",
  invalid_password: "Senha inválida",
  "password_must_contain_digit,_lower_case,_upper_case,_special_characters,_min_8_char_and_max_32_char.":
    "Senha deve conter dígitos, letra minúscula e maiúscula, caractere especial, mínimo de 8 caracteres e máximo de 32 caracteres,",
  "confirm_Password_does_not_match_password.": "As senhas não correspondem",
  please_accept_agreement: "Aceite os termos de serviço",
  please_enter_referral_code: "Insira o código de indicação",
  please_enter_new_password: "Preencha nova senha",
  please_enter_confirm_new_password: "Preencha confirmação de nova senha ",
  "confirm_new_password_does_not_match_new_password.":
    "Nova senha não corresponde",
  "please_enter_first_name.": "Preencha Nome",
  "please_enter_last_name.": "Preencha Sobrenome.",
  "please_enter_phone_number.": "Preencha Telefone",
  "please_enter_CPF/CNPJ#.": "Preencha CPF / CNPJ",
  "please_enter_date_of_birth.": "Preencha data de nascimento",
  "please_enter_company_name.": "Preencha nome da Empresa",
  phone_number_must_be_11_digits: "Telefone com DDD ",
  "CPF/CNPJ#_is_invalid.": "CPF / CNPJ está inválido",
  "please_enter_current_password.": "Preencha senha atual",
  "please_enter_new_password.": "Preencha nova senha",
  "please_enter_confirm_new_password.": "Preencha confirmação da nova senha",
  "password_doesn't_match.": "Senha não corresponde",
  "invalid_name,_only_contains_alphabets.": "Nome inválido",
  "image_size_cannot_be_greater_than_1MB.":
    "Imagem não pode ser superior a 1 MB",
  "image_size_cannot_be_greater_than_3MB.":
    "Imagem não pode ser superior a 3 MB",
  "you_are_below_18.": "Você não tem a idade permitida",
  please_select_spot_category: "Selecione categoria do Spot",
  please_select_spot_style: "Selecione Estilo do Spot",
  "please_enter_spot_title.": "Preencha Título do Spot",
  "please_enter_valid_hours.": "Preencha horários válidos",
  "please_enter_valid_hours_between_1_-_999.":
    "Preencha horários entre 1 - 999(sem decimal)",
  "please_enter_valid_discount_in_percentage_between_1_-_99.":
    "Preencha desconto válido entre 1% - 99% (sem decimal)",
  "please_enter_valid_minimum_booking_hours.":
    "Preencha mínimo de horas permitidas para a reserva",
  "please_enter_minimum_50_characters_in_description.":
    "Preencha com o mínimo de 50 caracteres na Descrição",
  "please_enter_minimum_5_characters_in_rules.":
    "Preencha com o mínimo de 5 caracteres nas regras",
  "please_enter_valid_booking_hours_between_1_-_999.":
    "Preencha horas válidas entre 1 e 999 ",
  "please_enter_maximum_guests_allowed.":
    "Preencha quantidade máximo de pessoas permitidas (até 99)",
  "maximum_8_guests_can_be_added.": "Máximo de 8 pessoas podem ser adicionadas",
  "please_enter_spot_description.": "Preencha descrição do Spot",
  please_select_spot_style: "Preencha Estilo do Spot",
  please_enter_street_address: "Preencha endereço",
  please_enter_complement: "Preencha Complemento",
  please_enter_state: "Preencha Estado",
  please_enter_valid_state: "Preencha válido Estado",
  Neigborhood: "Bairro",
  please_enter_neigborhood: "Preencha Bairro",
  please_enter_valid_neigborhood: "Preencha válido Bairro",
  number: "Número",
  please_enter_number: "Preencha Número",
  "please_select_street_address_from_suggestions.":
    "Selecione endereço das sugestões oferecidas",
  "please_enter_city.": "Preencha Cidade",
  "please_enter_valid_city.": "Preencha válido Cidade",
  please_enter_valid_number: "Preencha válido Número",
  please_enter_postal_code: "Preencha CEP",
  "please_enter_rules_&_instructions.": "Preencha regras & instruções",
  "please_enter_age_group.": "Preencha faixas etárias permitidas",
  "please_enter_cleaning_Fee.": "Preencha taxa de limpeza",
  "please_enter_valid_cleaning_fee_between_1_-_999.":
    "Preencha taxa de limpeza entre 1 e 999 (sem decimal)",
  "please_select_security_of_spot.": "Selecione segurança do Spot",
  "please_select_pet_friendly.": "Selecione condições de animais de estimação",
  "please_select_parking_options.": "Selecione opções de estacionamento",
  "please_select_cancellation_policy.": "Selecione política de cancelamento",
  please_select_available_parking_facilities:
    "Selecione facilidades de estacionamento",
  "please_enter_additional_parking_instructions.":
    "Preencha instruções de estacionamento",
  "please_enter_captcha.": "Preencha captcha",
  "please_select_start_time_and_end_time.": "Selecione horário de início e fim",
  please_select_weekend: "Selecione fim de semana",
  "please_add_slots.": "Adicione disponibilidade",
  please_add_minimum_five_images: "Adicionar mínimo de 5 fotos",
  please_add_minimum_one_images: "Adicionar mínimo de 1 fotos",
  please_select_days: "Selecionar dias",
  "please_enter_otp.": "Preencha código de verificação",
  "please_enter_valid_time_slot.": "Preencha horários disponíveis",
  "please_enter_add-ons.": "Preencha Adicionais",
  "please_enter_fee.": "Preencha taxas",
  "please_upload_picture.": "Adicione fotos",
  "please_select_terms_&_conditions.": "Selecione termos & condições",
  "please_select_privacy_policy.": "Selecione política de privacidade",
  "this_slot_is_already_taken,_please_select_different_slot.":
    "Esse horário não está mais disponível. Selecione outro horário",
  "no_record_found.": "Registro não encontrado",
  "please_select_date_&_time.": "Selecione data e horário",
  "please_enter_valid_postal_code.": "Preencha CEP válido",
  "please_enter_message.": "Preencha mensagem",
  "address_1_is_required.": "Endereço obrigatório ",
  "you_have_reached_maximum_limit_of_256_characters.":
    "Você atingiu o máximo de 256 caracteres",
  "address_2_is_required.": "Bairro obrigatório",
  "you_have_reached_maximum_limit_of_128_characters.":
    "Você atingiu o máximo de 128 caracteres",
  "minimum_13_characters_required.": "Mínimo de 13 caracteres ",

  wishlist: "Spots favoritos",
  listings: "anúncios",
  my_profile: "meu perfil",
  update_profile: "Informações de cadastro",
  change_password: "Mudar senha",
  payment_method: "método de pagamento",
  added_cards: "cartões adicionados",
  add_new_card: "adicionar novo cartão",
  billing_address: "Endereço de Cobrança",
  no_booking_found: "nenhuma reserva encontrada",
  message: "mensagem",

  "address_1_is_required.": "Address 1 is required.",
  "you_have_reached_maximum_limit_of_256_characters.":
    "You have reached maximum limit of 256 characters.",
  "address_2_is_required.": "Address 2 is required.",
  "you_have_reached_maximum_limit_of_128_characters.":
    "You have reached maximum limit of 128 characters.",
  "postal_code_is_required.": "Postal Code is required.",
  "city_is_required.": "City is required.",
  "you_have_reached_maximum_limit_of_64_characters.":
    "You have reached maximum limit of 64 characters.",
  "state_is_required.": "State is required.",
  "you_have_reached_maximum_limit_of_5_characters.":
    "You have reached maximum limit of 5 characters.",
  "card_holder's_name_is_required.": "Card Holder's Name is required.",
  "minimum_2_characters_required.": "Minimum 2 characters required.",
  "you_have_reached_maximum_limit_of_30_characters.":
    "You have reached maximum limit of 30 characters.",
  "minimum_13_characters_required.": "Minimum 13 characters required.",
  "you_have_reached_maximum_limit_of_19_characters.":
    "You have reached maximum limit of 19 characters.",

  "minimum_3_characters_required.": "Minimum 3 characters required.",
  "you_have_reached_maximum_limit_of_4_characters.":
    "You have reached maximum limit of 4 characters.",
  "current_password_is_required.": "Current Password is required.",
  "password_is_required.": "Password is required.",
  "maximum_32_characters_required.": "Maximum 32 characters required.",
  "password_must_contain_digit,_lower_case,_upper_case,_special_characters_and_min_8_char_max_32_char.":
    "Password must contain Digit, Lower case, Upper case, special Characters and min 8 char max 32 char.",
  "confirm_password_is_required.": "Confirm Password is required.",
  confirm_password_does_not_match_password:
    "Confirm Password does not match Password",
  "you_have_reached_maximum_limit_of_50_characters.":
    "You have reached maximum limit of 50 characters.",
  "name_is_required.": "Name is required.",
  "subject_is_required.": "Subject is required.",
  "description_is_required.": "Description is required.",
  "email_must_be_a_valid_email.": "Email must be a valid email.",
  "confirm_password_is_required.": "Confirm Password is required.",
  confirm_password_does_not_match_password:
    "Confirm Password does not match Password",
  //payment method
  payment_method: "Método de pagamento",
  add_card: "Adicionar cartão",
  "card_holder's_name": "Nome igual no cartão",
  card_number: "Número do cartão",
  expiry_month: "Mês de vencimento",
  expiry_year: "Ano de vencimento",
  cvv_number: "CVV",
  submit: "Adicionar",
  "adding_a_card_allows_quickspot_to_charge_you_for_reserved_bookings._this_information_is_securely_sent_to_our_payment_provider_and_never_stored_by_quickspot":
    "Adiconar um cartão permite que a Quickspot faça a cobrança das suas reservas. As informações são processadas de forma segura pelo provedor do cartão e nunca guardadas pela Quickspot",
  added_cards: "Cartões adicionados",
  add_new_card: "Adicionar novo cartão",
  billing_address: "Endereço de cobrança",
  "are_you_sure?": "Tem certeza?",
  "you_won't_be_able_to_revert_this!": "Você não poderá reverter isso!",
  "yes,_delete_it!": "Sim, deletar!",
  cancel: "Cancelar",

  "used_when_processing_payouts_and_payments.":
    "Necessário para processar pagamentos",
  address_1: "Endereço 1",
  address_2: "Complemento",
  country: "País",
  "state_(iso_format)": "Estado (UF)",
  city: "Cidade",
  // postal_code: "CEP",
  zip_code: "CEP",
  update_address: "Atualizar endereço",

  payouts: "Conta bancária",

  no_account_added: "Não foi adicionada uma conta",
  please_your_bank_account_details_so_we_can_send_you_your_payout_after_a_complete_booking:
    "Adicione seus dados bancários para receber os pagamentos dos aluguéis dos seus spots",
  add_your_bank: "Adicione seu banco",
  update_your_bank: "Atualize seu banco",
  holder_name: "Nome igual no cartão de crédito",
  holder_type: "Tipo de conta",
  holder_document: "CPF",
  bank: "Banco",
  branch_number: "Agência",
  branch_check_digit: "Dígito da agência (se houver)",
  account_number: "Número da conta",
  account_check_digit: "Dígito da conta (se houver)",
  type: "Tipo da conta (corrente ou poupança)",
  // update: "Atualizar",
  update: "Publicar",
  add: "Adicionar",

  //my calendar
  my_calendar: "Meu calendário",
  all_spots: "Todos os spots",
  "are_you_sure_you_want_to_cancel_booking?":
    "Tem certeza que deseja cancelar seu aluguel?",
  bad: "Muito ruim",
  poor: "Ruim",
  average: "Mais ou menos",
  good: "Bom",
  excellent: "Muito bom",
  view_feedback: "Ver avaliação",
  "how_do_you_rate_us?": "Como você avalia sua experiência com ",
  your_feedback: "Sua avaliação",
  feedback: "Avaliação",
  Submit_feedback: "Submeter avaliação",
  pay_per_hour: "Pagar por hora",
  pay_per_hour_subheading: "pagar_por_hora_subtítulo",
  "address_1_is_required.": "Necessário informar endereço 1",
  "you_have_reached_maximum_limit_of_256_characters.":
    "Você atingiu o limite máximo de 256 caracteres",
  "address_2_is_required.": "Necessário informar endereço 2",
  "you_have_reached_maximum_limit_of_128_characters.":
    "Você atingiu o limite máximo de 128 caracteres",
  "postal_code_is_required.": "Necessário informar o CEP",
  "city_is_required.": "Necessário informar a cidade",
  "you_have_reached_maximum_limit_of_64_characters.":
    "Você atingiu o limite máximo de 64 caracteres",
  "state_is_required.": "Necessário informar estado",
  please_enter_street: "Preencha Rua/Avenida",
  street: "Rua/Avenida",
  "you_have_reached_maximum_limit_of_5_characters.":
    "Você atingiu o limite máximo de 5 caracteres",
  "card_holder's_name_is_required.": "Necessário informar nome igual do cartão",
  "minimum_2_characters_required.": "Mínimo de 2 caracteres",
  "you_have_reached_maximum_limit_of_30_characters.":
    "Você atingiu o limite máximo de 30 caracteres",
  only_alphabets_are_allowed_for_this_field: "Apenas letras são permitidas ",
  "minimum_13_characters_required.": "Mínimo de 13 caracteres.",
  "you_have_reached_maximum_limit_of_19_characters.":
    "Você atingiu o limite máximo de 19 caracteres",

  "cvv_is_required.": "Necessário informar código de verificação do cartão.",
  "card_number_is_required.": "Necessário informar número de cartão.",

  "minimum_3_characters_required.": "Mínimo de 3 caracteres.",
  "you_have_reached_maximum_limit_of_4_characters.":
    "Você atingiu o limite máximo de 4 caracteres",
  "you_have_reached_maximum_limit_of_3_characters.":
    "Você atingiu o limite máximo de 3 caracteres",
  "current_password_is_required.": "Informe a senha atual",
  "password_is_required.": "Necessário informar senha",
  "maximum_32_characters_required.": "Máximo de 32 caracteres",
  "password_must_contain_digit,_lower_case,_upper_case,_special_characters_and_min_8_char_max_32_char.":
    "Senha deve conter letra minúscula, letra maiúscula, caractere especial, mínimo de 8 caracteres, máximo de 32 caracteres ",
  "confirm_password_is_required.": "Necessário confirmar senha",
  confirm_password_does_not_match_password:
    "Confirmação da senha não confere com a senha",
  "you_have_reached_maximum_limit_of_50_characters.":
    "Você atingiu o limite máximo de 50 caracteres",
  "name_is_required.": "Necessário informar o nome",
  "subject_is_required.": "Necessário informar assunto",
  "description_is_required.": "Necessário informar descrição",
  "email_must_be_a_valid_email.": "Email deve ser válido",
  "holder_name_is_required.": "Necessário informar o nome igual no cartão",
  "confirm_password_is_required.": "Necessáro confirmar senha",
  confirm_password_does_not_match_password:
    "Confirmação da senha não confere com a senha",

  "please_add_your_bank_account_details_before_listing_your_spot.":
    "Antes de publicar seu espaço, é necessário adicionar sua conta bancária. Clique abaixo, selecione 'Conta Bancária' no menu lateral e siga as instruções.",
  add_bank_account: "Adicionar conta bancária",
  no_booking_found: "Nenhuma reserva encontrada",
  all_bookings: "Todas as reservas",
  upcoming: "Próximas",
  completed: "Realizadas",
  cancelled: "Canceladas",
  cancel: "Cancelar",
  message: "Mensagem",
  exclamation_hover_text: "Adicione sua conta bancária para publicar seus spots.",

  add_extra_hours: "Adicione horas extras",
  pay_for_extra_hours: "Pague por horas extras",
  payments_for_extra_hours: "Pagamento de horas extras",
  booking_details_for_extra_hours: "Detalhes de reserva para horas extras",
  add_extra_hours_label: "Quantas horas extras gastas",
  enter_hours: "Insira o horário",
  extra_time_added: "Insira as horas adicionadas",
  extra_hours_details: "Informações sobre preços para horas extras",
  extra_hours_error_message: "Insira um valor de hora válido maior ou igual a 1.",

  text_published: "Publicado",
  text_publish: "Publicar",
  text_draft: "Rascunho",

  //updateprofile
  my_profile: "Seu perfil",
  // update_profile: "Atualizar perfil",
  update_the_following_information: "Atualize as informações do seu perfil",
  dob: "Data de nascimento",
  "cpf/cnpj": "CPF/CNPJ",

  be_a_part_of: "Faça parte",
  quickspotter_community: "comunidade do quickspotter",
  and_earn_by_hosting: "e receba com aluguéis ",
  "how_do_i_host?": "como disponibilizo meu spot?",

  how_do_i_host_heading_1: "Cadastre seu spot gratuitamente",
  how_do_i_host_description_1:
    "Descreva seu espaço, os benefícios e os horários disponíveis. E o melhor, defina suas regras e o seu preço.",
  how_do_i_host_heading_2: "Alugue o seu espaço por hora",
  how_do_i_host_description_2:
    "Você será notificado assim que receber reservas de acordo com a disponibilidade do seu spot.",
  how_do_i_host_heading_3: "Receba rendimentos pelo aluguel",
  how_do_i_host_description_3:
    "Receba seus rendimentos direto na sua conta assim que disponível.",
  create_your: "Crie o anúncio do seu spot",

  quickspot_listing: "minha lista de quickspot",
  get_started: "vamos começar",

  //my translate
  block_dates: "Bloquear datas",
  trending_topics: "Tópicos em alta",
  "email_is_required.": "Preencha seu email",
  styles: "Estilos",
  more_filter: "Mais filtros",
  view: "Visualizar",
  // delete_spot: "excluir ponto",
  // "are_you_sure_you_want_to_delete_this_spot?":
  //   "Tem certeza de que deseja excluir esses pontos?",
  delete_spot: "Excluir Spot",
  "are_you_sure_you_want_to_delete_this_spot?":
    "Tem certeza que deseja excluir esse spot? Você não poderá recuperá-lo.",
  yes: "sim",
  delete_: "Excluir",
  blocked_dates: "Datas bloqueadas",
  current_password: "Senha atual",
  type_is_required: "tipo é necessário.",
  "holder_type_is_required.": "tipo de suporte é necessário.",
  "type_is_required.": "tipo é necessário.",
  "bank_number_is_required.": "Número da agência é obrigatório.",
  "account_number_is_required.": "número da conta é obrigatório.",
  "bank_is_required.": "banco é necessário.",

  about_the_spot: "Sobre o spot",
  include_amenities_in_your_booking: "Comodidades",
  included_amenities_in_your_booking: "Comodidades",
  "add-ons_from_the_host": "Adicionais ofertados pelo anfitrião",
  category: "Categorias",
  style: "Estilo",
  parking: "Estacionamento",
  pets_are_allowed: "Animais de estimação são permitidos",
  pets_are_not_allowed: "Animais de estimação não são permitidos",
  see_more: "Ver mais",
  see_less: "Ver menos",

  start_booking: "Reservar",
  "no._of_attendees": "quantidade de participantes",
  "5+_hours_discount": "Desconto para 5+ horas",
  minimum: "Mínimo de",
  location: "Localização",
  host: "Quickspotter",
  verify_your_email: "Confirme seu email",
  "please_enter_the_4-digit_verification_code,_received_on_your_email":
    "Digite o código de 4 dígitos enviado para o seu email",
  verify: "Confirmar",
  "resend_verification_code?": "Reenviar código?",
  all_dates: "Qualquer data",
  more_filters: "Mais filtros",
  quick_spots: "Explore os spots",
  creator: "Criador",
  quickspotter: "Anfitrião",
  "please_enter_referral_code.": "Insira o código de indicação",
  you_must_accept_the_terms_and_conditions:
    "Você deve aceitar os Termos e Condições",
  price: "Preço",
  "no_spot_found,_please_try_with_different_info.":
    "nenhum spot encontrado, tente um novo filtro",
  sort_by: "Ordenar",
  price_ascending: "Preço crescente",
  price_descending: "Preço decrescente",
  space_found: "spots Encontrado",
  map_word: "Mapa",
  checking: "Corrente",
  savings: "Poupança",
  individual: "Pessoa física",
  company: "Pessoa jurídica",
  "you_have_reached_maximum_limit_of_1_characters.": "Máximo de 1 número",
  "account_check_digit_is_required.": "Dígito da conta é obrigatório.",
  "you_have_reached_maximum_limit_of_2_characters.": "Máximo de 2 números",
  "you_have_reached_maximum_limit_of_13_characters.": "Máximo de 13 números",
  save_this_card_for_future_payments:
    "Salvar este cartão para pagamentos futuros",
  date: "Data",
  pay_now: "Pagar agora",
  state: "Estado",
  enter_card_details: "Insira as informações do cartão de crédito",
  "you_must_review_and_accept_the_host's_rules_and_disclosure_to_book_the_space.":
    "Você deve ler e aceitar as regras to spot para concluir a reserva.",
  event: "Tipo de locação",
  off: "de desconto",
  brazil: "Brasil",
  address_two: "Complemento",
  edit: "editar",
  delete: "deletar",
  view_spot_details: "Ver detalhes do Spot",
  "you_can_book_slots_for_everyday._24/7_available.":
    "Este espaço está disponível para aluguel todos os dias, a qualquer hora.",
  "you_cannot_book_your_own_spot.": "Você não pode reservar seu próprio spot.",
  host_rules_description: "Regras e Instruções do Spot",
  "select_the_amenities_you_will_provide_at_your_spot._all_amenities_you_select_should_be_included_in_your_hourly_rate": `
    Inclua as comodidades dicionais que deseja cobrar pelo uso.
Exemplo: equipamentos de fotografia, tela chroma key, som, microfone, móvel, quadro, etc.`,
  parking_not_available: "não é disponibilizado vagas",
  i_have_reed_and_agreed_to_the: "Eu li e concordo com os",
  terms_and_conditions: "Termos de serviços",
  from_quickspot: "do Quickspot",
  upload_image: "Enviar Imagem",
  fee: "Taxa",
  "add-on": "Adicionar",
  "add-ons": "Complementos",
  "add_your_own_amenities_with_their_fee.":
    "Inclua as comodidades adicionais que deseja cobrar pelo uso. Exemplo: equipamentos de fotografia, tela chroma key, som, microfone, móvel, quadro, etc.",
  block_date: "Bloquear data",
  your_space_will_be_marked_unavailable:
    "Seu espaço será marcado como indisponível.",
  save: "Salvar",
  "blocked_date/time": "Data/hora bloqueada",
  parking_option: "Opção de estacionamento",
  parking_description: "Instruções adicionais para estationamento",
  no_amenities_found: "Nenhuma comodidade encontrada",
  no_addons_found: "nenhum complemento encontrado",
  hours: "hora(s)",
  leave_a_feedback: "Deixe uma avaliação",
  submit_feedback: "Enviar avaliação",
  photoshoot: "Sessão de fotos",
  video_session: "Gravação de vídeo",
  filming_for_streaming_platforms: "Gravação de vídeo",
  photo_and_video_session: "Filmagem televisão ou cinema",
  others: "Outros",
  break_up: "Romper",
  new_card: "Novo cartão",
  existing_card: "Existente cartão",
  select_card: "Selecione o cartão",
  filter: "Filtro",
  categories: "Categorias",
  clear: "Limpar",
  clear_all: "Limpar tudo",
  above_18: "Acima de 18",
  remove_file: "Remover arquivo",
  invalid_email: "E-mail inválido",
  dates_are_already_taken: "As datas já estão marcadas.",
  minimum_booking_is: "A reserva mínima é",
  "address_updated_successfully.": "Endereço atualizado com sucesso.",
  "card_deleted_successfully.": "Cartão excluído com sucesso.",
  "payment_successful!!": "Pagamento efetuado com sucesso!!",
  "card_added_successfully.": "Cartão adicionado com sucesso.",
  "please_complete_all_the_spot_steps_to_start_booking.":
    "Conclua todas as etapas do local para iniciar a reserva.",
  "all_fields_are_required.": "Todos os campos são necessários.",
  "the_expiry_date_is_before_today's_date._please_select_a_valid_expiry_date.":
    "A data de validade é antes da data de hoje. Selecione uma data de expiração válida.",
  payout: "Pagamento",
  discount_on: "desconto em",
  // list_spot: "Cadastrar Spot",
  list_spot: "Publicar",
  "Something went wrong":
    "Por favor, verifique se as informações da sua conta bancária estão corretas e tente novamente. Se você ainda estiver enfrentando problemas, entre em contato conosco pelo e-mail suporte@quickspot.com.",
  "Account updated": "Conta atualizada com sucesso",
  "Account updation failed":
    "Por favor, verifique se as informações da sua conta bancária estão corretas e tente novamente. Se você ainda estiver enfrentando problemas, entre em contato conosco pelo e-mail suporte@quickspot.com.",
  "The bank account entered is already default": "Conta Bancária já adicionada",
  "The recipient could not be created : invalid_parameter | legal_name | Value too short":
    "Por favor, verifique se as informações da sua conta bancária estão corretas e tente novamente. Se você ainda estiver enfrentando problemas, entre em contato conosco pelo e-mail suporte@quickspot.com.",

  "Account added succesfully": "Conta atualizada com sucesso",
  booked_by: "Reservado pelo criador",
  "booking_date/time": "Dia e horário do evento",
  create_password: "Criar senha",
  "no_blog_found.": "Nenhum blog encontrado.",
  "we_will_only_use_phone_number_for_booking_updates.":
    "Usaremos apenas o número de telefone para atualizações de reservas.",
  view_all: "Ver todos",
  hide: "Ocultar",

  //transaction
  transactions: "Sua carteira digital",
  transactions_subtitle:
    "Abaixo, estão os detalhes das transações dos seus spots. Após o criador efetuar o pagamento da reserva com o cartão de crédito, o valor levará 30 dias para ser processado e o repasse será feito automaticamente todas as quartas-feiras. Será descontada uma taxa de R$3,67 por repasse.",
  waiting_funds_amount: "Em processamento",
  available_amount: "Próximo repasse",
  transferred_amount: "Recebido",
  withdrawals: "Repasses",
  withdrawal_transaction_number: "Repasses",
  spot_name: "Nome do Spot",
  booking_date: "Data da reserva",
  amount_receivable: "Valor a receber",
  status: "Status",
  withdraw_transaction_id: "Número da transação",
  withdraw_date: "Data do repasse",
  amount: "Valor",
  addons_fees: "Adicionais",
  Amenities: "Comodidades",
  approve: "Aprovar reserva",
  reject: "Recusar reserva",
  "These are amenities that criadores typically look for in a spot. Select the ones that apply.":
    "Essas são comodidades que os criadores normalmente buscam em um spot. Selecione as que se aplicam.",

  "are_you_sure_you_want_to_reject_booking?":
    "A reserva foi cancelada. Vamos avisar o criador.",
  more: "Mais",

  "you_have_reached_maximum_limit_of_4000_characters.":
    "Você atingiu o máximo de 4000 caracteres.",
  please_enter_valid_postal_code: "Insira um CEP válido.",
  "please_enter_minimum_1000_characters_in_description.":
    // "Preencha com o mínimo de 1000 caracteres na Descrição",
    "Máximo de 1000 caracteres na Descrição",

  "please_enter_minimum_3_characters_in_title.": "mínimo de 3 caracteres",
  "please_enter_maximum_50_characters_in_title.": "máximo de 50 caracteres",
  "please_select_country.": "Preencha País",
  "please_enter_maximum_4000_characters_in_rules.":
    "Máximo de 4000 caracteres na Descrição",
  minimum_1_characters: "Mínimo de 1 caracteres",
  maximum_50_characters: "Máximo de 50 caracteres",
  neighborhood_is_required: "Preencha Bairro",
  portfolio: "Portfólio",
  portfolios: "Portfólios",
  "No Portfolio Found": "Portfólio não publicado",
  "List of all created portfolios": "Lista de todos os portfólios",
  "New Portfolio": "Novo portfólio",
  "Fill the following details": "Preencha os seguintes detalhes",
  "Select Spot": "Selecione o Spot",
  "Portfolio Name": "Portfólio Nome",
  "Portfolio Pictures": "Portfólio fotos",
  "Portfolio Description": "Portfólio Descrição",
  "please_select_spot": "Selecione do Spot",
  "Portfolio Name is Required.": "Preencha o nome do portfólio",
  "Portfolio Description is Required.": "Preencha a descrição do portfólio",
  "you_have_reached_maximum_limit_of_1000_characters":
    "Você atingiu o limite máximo de 1000 caracteres",
  "Maximum 10 images are allowed": "Máximo de 10 fotos",
  "delete_portfolio": "Excluir portfólio",
  "are_you_sure_you_want_to_delete_this_portfolio?":
    "Tem certeza que deseja excluir esse portfólio? Você não poderá recuperá-lo.",
  "Portfolio Created Successfully": "Portfólio criado com sucesso",
  "all": "Todos",
  "No Reviews Found": "Sem avaliações",
  "Reviews": "Avaliações",
  "profession": "Profissão",
  "Average reviews": "Avaliação média"
};
export default messages;
