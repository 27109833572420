const LocalStorageService = (function () {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setAuth(token) {
    localStorage.setItem("setRecord", token);
  }
  function _setToken(tokenObj) {
    localStorage.setItem("access_token", tokenObj.access_token);
    localStorage.setItem("refresh_token", tokenObj.refresh_token);
  }
  function _getAccessToken() {
    return localStorage.getItem("access_token");
  }
  function _getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }
  function _clearToken() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }
  function _setMatter(matter) {
    localStorage.setItem("matter", matter);
  }
  function _getAuth() {
    return localStorage.getItem("setRecord");
  }
  function _getMatter() {
    return localStorage.getItem("matter");
  }
  function _setClient(client) {
    localStorage.setItem("client", client);
  }
  function _getLocale() {
    return localStorage.getItem("locale");
  }
  function _setLocale(value) {
    localStorage.setItem("locale", value);
  }

  function _getClient() {
    return localStorage.getItem("client");
  }

  function _checkAdmin() {
    return localStorage.getItem("user_permission");
  }

  function _setTaskURL(url) {
    localStorage.setItem("taskLink", url);
  }

  function _getTaskURL() {
    return localStorage.getItem("taskLink");
  }

  function _clearTaskURL() {
    localStorage.removeItem("taskLink");
  }

  function _isAdmin() {
    try {
      if (!_isLoggedIn()) {
        return false;
      }
      let accessToken = _getAccessToken();
      var tokens = accessToken.split(".");
      let payload = JSON.parse(atob(tokens[1]));
      if (
        !payload.resource_access ||
        !payload.resource_access.CMS.roles.length
      ) {
        return false;
      }
      localStorage.setItem("role", payload.resource_access.CMS.roles[0]);
      return payload.resource_access.CMS.roles[0] === " CMS_ADMIN";
    } catch (err) {}
  }
  function _isLoggedIn() {
    if (!_getAccessToken() || _getAccessToken() === undefined) {
      return false;
    }
    return true;
  }

  function _logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("matter");
    localStorage.removeItem("client");
    localStorage.removeItem("role");
    localStorage.removeItem("user_permission");
    localStorage.clear();
    // sessionStorage.removeItem("user_permission");
    // sessionStorage.clear();
  }

  return {
    getService: _getService,
    setToken: _setToken,
    setMatter: _setMatter,
    getMatter: _getMatter,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    isAdmin: _isAdmin,
    isLoggedIn: _isLoggedIn,
    setClient: _setClient,
    getClient: _getClient,
    logout: _logout,
    checkAdmin: _checkAdmin,
    setAuth: _setAuth,
    getAuth: _getAuth,
    setTaskURL: _setTaskURL,
    getTaskURL: _getTaskURL,
    clearTaskURL: _clearTaskURL,
    getLocale: _getLocale,
    setLocale: _setLocale,
  };
})();
export default LocalStorageService;
