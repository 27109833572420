import { t } from "i18next";
import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { imagePath } from "../../../constants/imageUrl";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 2px;
  border-radius: 10px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: black;
  font-weight: bold;
  font-size: 1.4rem;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

function DropBox({ onDrop }) {
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    isDragAccept,
    isFocused,
    isDragReject,
    rejectedFiles,
    isDragActive,
  } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
    },
    onDrop,
    noClick: true,
    noKeyboard: true,
    minSize: 0,
    maxSize: 3 * 1024 * 1024, //3MB max size
    multiple: true,
  });

  return (
    <>
      {" "}
      <div className="">
        <Container
          className="dropbox fs-13 fw-normal text-default-400 bg-white border-2 "
          {...getRootProps({
            isDragAccept,
            isFocused,
            isDragReject,
            rejectedFiles,
            isDragActive,
          })}
        >
          <input {...getInputProps()} />
          {!isDragActive && (
            <p className="mb-0">
              <strong className="me-1">{imagePath.topArrowIcon}</strong>
              {t("drag_and_drop_files_here_or")}
              <span className="text-link fw-semibold" onClick={open}>
                {" "}
                {t("choose_files")}
              </span>
            </p>
          )}
          {isDragActive && !isDragReject && "Drop it like it's hot!"}
          {isDragReject && "File type not accepted, sorry!"}

          {/* {rejectedFiles?.length > 0 && rejectedFiles[0].size > 1048576 && (
                        <div className="text-danger mt-2">
                            File is too large.
                        </div>
                    )} */}
        </Container>
      </div>
    </>
  );
}

export default DropBox;
