import { loginTokenHeader, generalHeader } from "../auth-header";
import axios from "axios";
import { adminApi } from "../../api";

// access token for authentication
export function authTokenHeader() {
  let accessToken = localStorage.getItem("admin-token");
  if (accessToken) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    };
  } else {
    window.location.reload(true);
    return {};
  }
}

export const loginApiCall = async (email, password) => {
  const formData = {
    email: email,
    password: password,
  };
  return await axios.post(adminApi.login, formData, {
    headers: loginTokenHeader(),
  });
};

export const forgotPasswordApiCall = async (email) => {
  const formData = {
    email: email,
  };
  return await axios.post(adminApi.forgotPassword, formData, {
    headers: generalHeader(),
  });
};

export const resetPasswordApiCall = async (email, key, password) => {
  const formData = {
    email: email,
    key: key,
    password: password,
  };
  return await axios.post(adminApi.updatePassword, formData, {
    headers: generalHeader(),
  });
};

export const logoutApiCall = async () => {
  return await axios.post(adminApi.logout);
};

export const changePasswordApiCall = async (oldPassword, newPassword) => {
  const formData = {
    oldPassword,
    newPassword,
  };
  return await axios.post(`${adminApi.changePassword}`, formData, {
    headers: authTokenHeader(),
  });
};
