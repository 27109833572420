import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import ListSpotNew from "./component/Pages/Listspot/ListSpotNew";

// User Componenets Import
const Home = lazy(() => import("./component/Home/Home"));
const Login = lazy(() => import("./component/Authentication/Login"));
const Signup = lazy(() => import("./component/Authentication/Signup/Signup"));
const Payment = lazy(() => import("./component/Pages/Payment/payment"));
const ExtraPayment = lazy(() => import("./component/Pages/Payment/ExtraPayment"));
const ForgotPassword = lazy(() =>
  import("./component/Authentication/Forgotpassword")
);
const ResetPassword = lazy(() =>
  import("./component/Authentication/ResetPassword")
);
const CreatePassword = lazy(() =>
  import("./component/Authentication/CreatePassword")
);
const CompleteProfile = lazy(() =>
  import("./component/Authentication/Signup/CompleteProfile")
);
const ListSpot = lazy(() => import("./component/Pages/Listspot/Listspot"));
const ThankuScreen = lazy(() =>
  import("../src/component/Pages/Listspot/ThankuScreen")
);

const SearchResult = lazy(() => import("./component/Home/search/SearchResult"));
const SpotDetail = lazy(() =>
  import("./component/Pages/MyListing/SpotDetails/SpotDetail")
);
const SpotBooking = lazy(() =>
  import("./component/Pages/SpotBooking/SpotBooking")
);

const PublicList = lazy(() => import("./component/Pages/Listspot/Publiclist"));
// const PublicList = lazy(() => import("./component/Pages/Listspot/Publiclist"));
const MyBookings = lazy(() =>
  import("./component/Pages/MyBookings/MyBookings")
);

const MyBookingDetail = lazy(() =>
  import("./component/Pages/MyBookings/BookingDetails/BookingDetail")
);
const MyListings = lazy(() => import("./component/Pages/MyListing/MyListings"));
const Messages = lazy(() => import("./component/Pages/Messages/Messages"));
const WishList = lazy(() => import("./component/Pages/WishList/WishList"));
const ReferEarn = lazy(() => import("./component/Pages/ReferEarn/ReferEarn"));
const AllCategory = lazy(() =>
  import("./component/Pages/AllCategories/AllCategories")
);
const AllCities = lazy(() => import("./component/Pages/AllCities/AllCities"));

const HelpPage = lazy(() => import("./component/Pages/Help/Help"));
const Blog = lazy(() => import("./component/Pages/Blog/Blog"));
const BlogDetail = lazy(() => import("./component/Pages/Blog/BlogDetail"));
const Contact = lazy(() => import("./component/Pages/Contact/Contact"));
const AudiovisualProductions = lazy(() => import("./component/Pages/AudiovisualProductions/AudiovisualProductions"));
const UpdateProfile = lazy(() =>
  import("./component/Pages/UpdateProfile/UpdateProfile")
);
const TermsConditions = lazy(() =>
  import("./component/Pages/ContentPages/TermsConditions")
);
const Privacy = lazy(() => import("./component/Pages/ContentPages/Privacy"));

const Transaction = lazy(() => import("./component/Pages/Transaction"));

const Portfolio = lazy(() => import("./component/Pages/Portfolio"))
// User Base Path
export const basePath = process.env.REACT_APP_BASE_URL;
// const basePath = '/ms/quickspot/stage/frontend';

// Admin Base Path
const admin = "/admin";
// const basePathAdmin = `/ms/quickspot/qa/frontend${admin}`;

const basePathAdmin = process.env.REACT_APP_BASE_URL + admin;
// const basePathAdmin = `/ms/quickspot/stage/frontend${admin}`

// User Routes
export const userRoutes = {
  home: {
    name: "home",
    component: <Home />,
    exact: true,
    path: `${basePath}/`,
  },
  portfolio: {
    name: "home",
    component: <Portfolio />,
    exact: true,
    path: `${basePath}/portfolio/:userId`,
  },
  login: {
    name: "login",
    component: <Login />,
    path: `${basePath}/login`,
  },
  signup: {
    name: "signup",
    component: <Signup />,
    path: `${basePath}/signup`,
  },
  completeProfile: {
    name: "Complete Profile",
    component: <CompleteProfile />,
    path: `${basePath}/complete-profile`,
  },
  forgotPassword: {
    name: "forgotPassword",
    component: <ForgotPassword />,
    path: `${basePath}/forgot-password`,
  },
  resetPassword: {
    name: "resetPassword",
    component: <ResetPassword />,
    path: `${basePath}/reset-password`,
  },

  createPassword: {
    name: "resetPassword",
    component: <CreatePassword />,
    path: `${basePath}/create-password`,
  },

  payments: {
    name: "Payment",
    component: <Payment />,
    path: `${basePath}/payment`,
  },
  
  extraPayments: {
    name: "Extra Payment",
    component: <ExtraPayment />,
    path: `${basePath}/extra-payment`,
  },

  myListing: {
    name: "My Listing",
    component: <MyListings />,
    path: `${basePath}/my-listing`,
  },
  listSpotDetail: {
    name: "Spot Detail",
    component: <SpotDetail />,
    path: `${basePath}/spot-detail`,
  },
  listSpot: {
    name: "Add List Spot",
    component: <ListSpotNew />,
    path: `${basePath}/list-spot`,
  },
  editListSpot: {
    name: "Edit List Spot",
    component: <ListSpotNew />,
    path: `${basePath}/edit-list-spot`,
  },
  addSpotThanku: {
    name: "Add Spot Thanku ",
    component: <ThankuScreen />,
    path: `${basePath}/add-spot-thanku`,
  },

  updateProfile: {
    name: "updateProfile",
    component: <UpdateProfile />,
    path: `${basePath}/update-profile`,
  },

  myWishlist: {
    name: "My Wishlist",
    component: <WishList />,
    path: `${basePath}/my-wishlist`,
  },

  blogs: {
    name: "Blogs",
    component: <Blog />,
    path: `${basePath}/blogs`,
  },
  blogDetails: {
    name: "Blog Details",
    component: <BlogDetail />,
    path: `${basePath}/blog`,
  },

  allCategories: {
    name: "All Category",
    component: <AllCategory />,
    path: `${basePath}/all-category`,
  },
  allCities: {
    name: "All Cities",
    component: <AllCities />,
    path: `${basePath}/all-cities`,
  },

  referral: {
    name: "Referral",
    component: <ReferEarn />,
    path: `${basePath}/referral`,
  },

  transactions: {
    name: "transactions",
    component: <Transaction />,
    path: `${basePath}/transactions`,
  },
  spotBooking: {
    name: "Spot Booking",
    component: <SpotBooking />,
    path: `${basePath}/spot-booking`,
  },

  myBookings: {
    name: "My Bookings",
    component: <MyBookings />,
    path: `${basePath}/my-bookings`,
  },
  bookingDetails: {
    name: "Booking Details",
    component: <MyBookingDetail />,
    path: `${basePath}/booking-details`,
  },
  inbox: {
    name: "Inbox / Messages",
    component: <Messages />,
    path: `${basePath}/inbox`,
  },
  searchResult: {
    name: "searchResult",
    component: <SearchResult />,
    path: `${basePath}/search-result`,
  },
  publicList: {
    name: "Public List",
    component: <PublicList />,
    path: `${basePath}/publiclist`,
  },

  helpPage: {
    name: "Help Center",
    component: <HelpPage />,
    path: `${basePath}/help-center`,
  },

  contactUs: {
    name: "Contact Us",
    component: <Contact />,
    path: `${basePath}/contact-us`,
  },

  audiovisualProductions: {
    name: "Audiovisual Productions",
    component: <AudiovisualProductions />,
    path: `${basePath}/find-a-space`,
  },

  SpotBooking: {
    name: "/spot-booking",
    component: SpotBooking,
    path: `${basePath}/spotbooking`,
  },

  termsConditions: {
    name: "termsConditions",
    path: `${basePath}/terms-conditions`,
  },
  privacyPolicy: {
    name: "privacyPolicy",
    path: `${basePath}/privacy-policy`,
  },
  serviceAgreement: {
    name: "Service Agreement",
    path: `${basePath}/service-agreement`,
  },
  cancellationPolicies: {
    name: "cancellationPolicies",
    path: `${basePath}/cancellation-policies`,
  },
  refundPolicies: {
    name: "refundPolicies",
    path: `${basePath}/refund-policies`,
  },

  transaction: {
    name: "transaction",
    component: <Transaction />,
    path: `${basePath}/transaction`,
  },
};
