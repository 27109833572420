import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import english from "./en/translation";
import portuguese from "./pt/translation";

i18n
  // load translation using http -> see /public/locales
  .use(Backend)

  // detect user language
  // .use(LanguageDetector)
  //    pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // init i18next
  .init({
    fallbackLng: "pt",
    debug: true,

    resources: {
      en: {
        translation: english,
      },
      pt: {
        translation: portuguese,
      },
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
