import { generalHeader, imgUploadHeader } from "./auth-header";
import axios from "axios";
import { userApi } from "../api";

// Upload images or post Api for all images on s3
export const postImageOnApiCall = async (image) => {
  const formData = new FormData();
  formData.append("image", image);
  return await axios.post(userApi.images, formData, {
    headers: imgUploadHeader(),
  });
};

// get Api for all images on s3
export const getImageOnApiCall = async (id) => {
  return await axios.get(userApi.images, { headers: generalHeader() });
};
