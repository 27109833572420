const messages = {
  //common
  //days
  monday: "MONDAY",
  tuesday: "TUESDAY",
  wednesday: "WEDNESDAY",
  thursday: "THURSDAY",
  friday: "FRIDAY",
  saturday: "SATURDAY",
  sunday: "SUNDAY",
  //months
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",

  map_word: "Map",

  to: "To",
  from: "From",
  time: "Time",
  start_time: "Start Time",
  end_time: "End Time",

  //common in pages
  sign_up: "Sign Up",
  email_address: "Email address",
  login: "Login",
  list_your_spot: "List Your Spot",

  //login page
  welcome_back: "Welcome Back",
  login_to_quickspot: "Login to Quickspot",
  password: "Password",
  remember_me: "Remember Me",
  "forgot_password?": "Forgot Password?",
  login_button: "Login",
  or_login_using: "OR Login Using",
  "don't_have_an_account?": "Don't have an account?",

  //signup page
  join_quickspot: "Join Quickspot",
  confirm_password: "Confirm Password",
  referral_code: "Referral Code",
  enter_the_following_details: "Enter the following details",
  "i_agree_to_quickspot's": "I agree to Quickspot's",
  service_agreement: "Service Agreement",
  and: "and",
  privacy_policy: "Privacy Policy",
  "i_do_not_want_to_receive_marketing_emails_from_quickspot.":
    "I do not want to receive marketing emails from Quickspot.",
  "quickspots_will_send_you_deals,_inspiration_and_marketing_emails._you_can_opt_out_at_anytime_from_you_account_setting":
    "Quickspots will send you deals, inspiration and marketing emails. You can opt out at anytime from you account setting",
  or_sign_up_using: "OR Sign Up Using",
  "already_have_an_account?": "Already have an account?",

  //Forgot Password
  forgot_password: "Forgot Password",
  enter_your_email_address: "Enter your email address",
  send: "Send",
  back_to: "Back to",

  //header
  home: "Home",
  inbox: "Inbox",
  my_bookings: "My Bookings",
  my_listings: "My Listings",
  my_wishlist: "My Wishlist",
  profile: "Profile",
  "refer_&_earn": "Refer & Earn",
  add_spot: "Add Spot",
  faq_and_help_center: "FAQ and Help Center",
  logout: "Logout",

  //footer
  quick_links: "Quick Links",
  list_your_spot: "List Your Spot",
  blog: "Blog",
  contact: "Contact",
  support: "Support",
  "faq_&_help_center": "FAQ & Help Center",
  legal: "Legal",
  "terms_&_conditions": "Terms & Conditions",
  privacy_policy: "Privacy Policy",
  cancellation_policies: "Cancellation Policies",
  refund_policies: "Refund Policies",
  social_links: "Social Links",

  //home page
  find_your_ideal_spot: "Find Your Ideal Spot",
  find_your_ideal_spot_sub_heading:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit lorem solore.",
  spot_type: "Spot Type",
  select_category: "select_category",
  spot_style: "Spot Style",
  select_style: "select_style",
  "where?": "Where?",
  "when?": "When?",
  select: "Select",
  search: "Search",

  //Main Spots section
  main_spots: "Main Spots",
  view_all_categories: "View All Categories",

  //How It Works? section
  "how_it_works?": "How It Works?",
  how_it_works_subheading:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit lorem solore.",
  select_category: "Select Category",
  select_category_subheading:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit lorem solore.",
  search_spot: "Search Spot",
  search_spot_subheading:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit lorem solore.",
  book_spot: "Book Spot",
  book_spot_subheading:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit lorem solore.",

  //why_quickspot section
  "why_quickspot?": "Why Quickspot?",
  why_quickspot_subheading:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit lorem solore.",
  many_types_of_places_to_shoot: "Many types of places to shoot",
  many_types_of_places_to_shoot_subheading:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  simple_safe_and_accessible: "Simple, safe and accessible",
  simple_safe_and_accessible_subheading:
    "Led do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  select_the_period_you_need_the_spot: "Select the period you need the spot",
  select_the_period_you_need_the_spot_subheading:
    "Led orem ipsum dolor sit amedo eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  know_exactly_what_waits_for_you: "Know exactly what waits for you",
  know_exactly_what_waits_for_you_subheading:
    "Led orem ipsum dolor sit amedo eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  pay_per_hour: "Pay per hour",
  pay_per_hour_subheading:
    "Led orem ipsum dolor sit amedo eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",

  //review section
  reviews: "Reviews",
  what_our_clients_say_about_us: "What our clients say about us",
  looker_since: "Looker since ",

  //become a spotter section
  "become_a_spotter!": "Become a Spotter!",
  become_a_spotter_subheading:
    " Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci",
  join_now: "Join Now",

  //city section
  we_have_unlocked_places_all_over_brazil:
    "We have unlocked places all over Brazil",
  view_all_the_cities: "View All The Cities",

  //Message inbox page
  messages: "Messages",
  search_messages: "Search Messages",
  all_messages: "All Messages",
  "write_something...": "Write Something...",

  //reset Password
  reset_password: "Reset Password",
  enter_new_password: "Enter new password",
  new_password: "New Password",
  confirm_new_password: "Confirm New Password",
  submit: "Submit",

  //complete profile
  complete_your_profile: "Complete Your Profile",
  this_helps_building_trust_between_guests_and_hosts:
    "This helps building trust between guests and hosts",
  first_name: "First Name",
  last_name: "Last Name",
  phone_number: "Phone Number",
  we_will_only_use_phone_number_for_booking_updates:
    "We will only use phone number for booking updates",
  cpf: "CPF",
  cnpj: "CNPJ",
  date_of_birth: "Date of Birth",
  company_name: "Company Name",
  how_did_you_hear_about_us: "How did you hear about us",
  linkedin: "LinkedIn",
  friend: "Friend",
  google: "Google",
  complete_profile: "Complete Profile",
  upload_profile_picture: "Upload Profile Picture",
  supported_files: "Supported files",
  png: "PNG",
  jpg: "JPG",
  jpeg: "JPEG",

  //Contact us page
  contact_us: "Contact Us",
  tell_us_how_can_we_help: "Tell us how can we help",
  phone: "Phone",
  email: "Email",
  if_you_have_any_query: "If you have any Query",
  get_in_touch_with_us: "Get in touch with us",
  your_email_address: "Your Email Address",
  your_name: "Your Name",
  subject: "Subject",
  description: "Description",
  drag_and_drop_files_here_or: "Drag and Drop files here or",
  choose_files: "Choose Files",
  submit: "Submit",

  //faq and help center page
  the_faqs: "The FAQs",
  help_center: "Help Center",
  everything_you_need_to_know_about_our_services:
    "Everything you need to know about our services",
  search_topic: "Search Topic",
  lookers: "Lookers",
  spotters: "Spotters",
  looker: "looker",
  spotter: "spotter",
  everything_quickspot_lookers_need_to_know_about_how_to_use_our_marketplace_to_book_inspiring_spaces:
    "Everything QuickSpot lookers need to know about how to use our marketplace to book inspiring spaces",
  everything_quickspot_hosts_need_to_know_about_how_to_use_our_marketplace_to_book_inspiring_spaces:
    "Everything QuickSpot hosts need to know about how to use our marketplace to book inspiring spaces",
  help_topics_for_lookers: "Help Topics for Lookers",
  help_topics_for_spotters: "Help Topics for Spotters",
  frequently_asked_questions: "Frequently Asked Questions",
  "did_not_find_the_desired_answer?": "Did not find the desired answer?",
  ask_your_own_question: "Ask Your Own Question",

  //list your spot header
  list_your_spot: "List Your Spot",
  edit_your_spot: "Edit Your Spot",
  spot_details: "Spot Details",
  spot_pictures: "Spot Pictures",
  operating_hours: "Operating Hours",
  amenities: "Amenities",

  //list your spot step-1
  basic_details: "Basic Details",
  spot_category: "Spot Category",
  select_category: "Select Category",
  spot_style: "Spot Style",
  select_style: "Select Style",
  spot_title: "Spot Title",
  hourly_rate: "Hourly Rate",
  cleaning_fee: "Cleaning Fee",
  discount_percent_will_apply_on_hours_5_bookings_only:
    "Discount % will apply on hours 5+ bookings only.",
  minimum_booking_hours: "Minimum booking hours",
  maximum_guests_allowed: "Maximum guests allowed",
  spot_description: "Spot Description",
  address: "Address",
  it_will_only_shared_with_guests_after_they_book_the_space:
    "It will only shared with guests after they book the space",
  country: "Country",
  please_select_country: "Please Select Country",
  street_address: "Street Address",
  address_2: "Address 2",
  city: "City",
  state: "State",
  postal_code: "Postal Code",
  rules_and_instructions: "Rules & Instructions",
  include_all_does_and_donts_of_the_house_rules:
    "Include all do's and don'ts of the house rules",
  eg_no_smoking_on_the_building_outside_catering_is_allowed_etc:
    "Eg. no smoking on the building, outside catering is allowed etc.",
  back: "Back",
  next: "Next",

  //list your spot step-2
  security_of_spot: "Security of Spot",
  is_security_cameras_and_recording_devices_installed:
    "Is security cameras and recording devices installed",
  pet_friendly: "Pet Friendly",
  is_pet_allowed_at_your_spot: "Is pet allowed at your spot",
  parking_options: "Parking Options",
  is_parking_available_at_your_spot: "Is parking available at your spot",
  select_available_parking_facilities: "Select available parking facilities",
  free_onsite_parking: "Free Onsite Parking",
  velvet: "Velvet",
  paid_street_parking: "Paid Street Parking",
  free_street_parking: "Free Street Parking",
  nearby_parking: "Nearby Parking",
  metered_street_parking: "Metered Street Parking",
  additional_parking_instructions: "Additional Parking Instructions",
  "Who's_allowed_in_the_house": "Who's allowed in the house?",
  include_the_age_group_who_are_allowed:
    "Include the age group who are allowed",
  age_group: "Age Group",
  age_group_above_18: "AGE GROUP ABOVE 18",
  all_age_group: "All Age Group",
  spot_pictures: "Spot Pictures",
  photo_requirements: "Photo requirements",
  high_resolution: "High resolution",
  at_least_1000_pixels_wide: "At least 1000 pixels wide",
  horizontal_orientation: "Horizontal Orientation",
  no_vertical_photos: "No vertical photos",
  color_photos: "Color Photos",
  no_black_and_white: "No black and white",
  no_collage_screenshot_or_watermark: "No collage, screenshot or watermark",
  add_minimum_5_high_quality_images: "Add minimum 5 high quality images",
  maximum_file_size_is_3_mb: "Maximum file size is 3 MB",
  drag_and_drop_files_here_or: "Drag and Drop files here or",
  choose_files: "Choose Files",

  //list your spot step-3

  spot_operation_hours: "Spot Operation hours",
  operating_hours_are_the_days_and_hours_of_the_week_that_your_space_is_open_to_host_bookings:
    "Operating hours are the days and hours of the week that your space is open to host bookings",
  everyday: "Everyday",
  only_weekend: "Only Weekend",
  selected_days: "Selected Days",
  select_from_calendar: "Select from Calendar",
  "selected_date/time": "Selected Date/Time",
  no_record_found: "No Record Found",
  cancellation_policy: "Cancellation Policy",

  cancellation_policy_description:
    "All such reservations are 100% refundable unless canceled up to 24 hours after the reservation is requested.",
  //booking details
  send_booking_request: "Send Booking Request",
  your_host_has_2_business_days_to_respond_to_your_booking_request:
    "Your host has 2 business days to respond to your booking request",
  date_and_time: "Date & Time",
  edit_date_and_time: "Edit date and time",
  included_amenities: "Included Amenities",
  leave_note_to_your_host: "Leave note to your host",
  note: "Note",
  host_rules: "Host Rules",
  general_rules: "General Rules",
  i_accept_and_understand_these_host_rules_disclosure:
    "I accept and understand these host rules disclosure.",
  booking_details: "Booking Details",
  make_payment: "Make Payment",
  you_must_accept_the_host_rules: "You must accept the host rules.",
  service_fee_and_taxes: "Service fee and taxes",
  transaction_fees: "Transaction fees",
  total: "Total",

  //payment
  payments: "Payments",

  //validation messages
  "please_enter_email.": "Please Enter Email.",
  invalidEmail: "Email is Invalid.",
  emptyPasswordField: "Please Enter Password.",
  emptyConfirmPasswordField: "Please Enter Confirm Password.",

  invalidPassword: "Invalid Password",
  passwordDescription:
    "Password must contain Digit, Lower case, Upper case, special Characters, min 8 char and max 32 char.",
  confirmPasswordNotMatched: "Confirm Password does not match Password.",
  acceptServiceAgreement: "Please Accept Agreement",
  referralCodeRequired: "Please Enter Referral Code",
  emptyNewPasswordField: "Please Enter New Password",
  emptyNewConfirmPasswordField: "Please Enter Confirm New Password",
  confirmNewPasswordNotMatched:
    "Confirm New Password does not match New Password.",
  firstNameEmpty: "Please Enter First Name.",
  lastNameEmpty: "Please Enter Last Name.",
  phoneNumberRequired: "Please Enter Phone Number.",
  CPFCNPJrequired: "Please Enter CPF / CNPJ#.",
  DOBRequired: "Please Enter Date Of Birth.",
  companyNameRequired: "Please Enter Company Name.",
  invalidPhoneNumber: "Phone Number must be 11 digits",
  invalidCPFCNPJ: "CPF / CNPJ# is invalid.",
  currentPasswordRequired: "Please Enter Current Password.",
  newPasswordRequired: "Please Enter New Password.",
  retypeNewPasswordRequired: "Please Enter Confirm New Password.",
  matchNewPassword: "Password doesn't match.",
  invalidName: "Invalid Name, Only contains Alphabets.",
  imageUpladSize: "Image Size cannot be greater than 1 MB.",
  belowAge: "You are below 18.",
  selectSpotCategory: "Please select Spot Category.",
  selectSpotStyle: "Please select Spot Style",
  spotTitleRequired: "Please Enter Spot Title.",
  hoursRequired: "Please Enter Valid Hours.",
  invalidHours: "Please Enter Valid Hours between 1 - 5000.",
  invalidDiscount: "Please Enter Valid discount in percentage between 1 - 99.",
  minBookingHrRequired: "Please Enter Valid Minimum Booking Hours.",
  spotDescriptionLimit: "Please enter Minimum 50 characters in Description.",
  spotRulesLimit: "Please enter Minimum 5 characters in Rules.",
  invalidMinBooking: "Please Enter Valid Booking Hours between 1 - 999.",
  maxGuestsRequired: "Please Enter Maximum Guests Allowed.",
  invalidMaxBooking: "Maximum 8 Guests can be added. ",
  spotDescriptionRequired: "Please Enter Spot Description.",
  spotStyleRequired: "Please Select Spot Style",
  streetAddressRequired: "Please Enter Street Address",
  streetAddressFromDropdown: "Please select Street Address from suggestions.",
  cityRequired: "Please Enter City.",
  // please_enter_state: "Please Enter State.",
  postalCodeRequired: "Please Enter Postal Code.",
  rulesInsturctionsRequired: "Please Enter Rules & Instructions.",
  ageGroupRequired: "Please Enter Age Group.",
  cleaningFeeRequired: "Please Enter Cleaning Fee.",
  invalidCleaningFee: "Please Enter Valid Cleaning Fee between 1 - 999.",
  securityRequired: "Please Select Security of Spot.",
  petFriendlyRequired: "Please Select Pet Friendly.",
  parkingOptionsRequired: "Please Select Parking Options.",
  cancelationPolicyRequired: "Please Select Cancellation Policy.",
  availableParkingRequired: "Please Select Available Parking Facilities",
  additionalParkingInstructionsRequired:
    "Please Enter Additional Parking Instructions.",
  captchaRequired: "Please Enter Captcha.",
  selectStartOrEndTime: "Please Select Start Time and End Time.",
  selectWeekend: "Please Select Weekend",
  addDates: "Please Add Slots.",
  minFiveImages: "Please Add Minimum Five Images",
  selectSelectedDays: "Please Select Days",
  enterOtp: "Please Enter OTP.",
  validTime: "Please Enter Valid Time Slot.",
  enterFieldAddOns: "Please Enter Add-Ons.",
  enterFieldFee: "Please Enter Fee.",
  uploadAddOnPic: "Please Upload Picture.",
  requiredtermsCondition: "Please Select Terms & Conditions.",
  requiredPrivacyPolicy: "Please Select Privacy Policy.",
  slotBooked: "This Slot is already taken, Please select different slot.",
  selectDateAndTime: "Please Select Date & Time.",
  invalidPostalCode: "Please Enter Valid Postal Code.",
  messageRequired: "Please Enter Message.",
  "address_1_is_required.": "Address 1 is required.",
  "you_have_reached_maximum_limit_of_256_characters.":
    "You have reached maximum limit of 256 characters.",
  "address_2_is_required.": "Address 2 is required.",
  "you_have_reached_maximum_limit_of_128_characters.":
    "You have reached maximum limit of 128 characters.",
  "minimum_13_characters_required.": "Minimum 13 characters required.",

  //new messages

  "please_add_your_bank_account_details_before_listing_your_spot.":
    "Before publishing your spot, it's necessary to add your bank account. Click below, select 'Bank Account' from the side menu, and follow the instructions.Add bank account.",
  add_bank_account: "Add bank account",
  no_booking_found: "No Booking found",
  all_bookings: "All Booking",
  upcoming: "Upcoming",
  completed: "Completed",
  cancelled: "Cancelled",
  cancel: "Cancel",
  message: "Message",
  exclamation_hover_text: "Add your bank account to publish your spots.",
  
  add_extra_hours: "Add extra hours",
  pay_for_extra_hours: "Pay for extra hours",
  payments_for_extra_hours: "Payment for extra hours",
  booking_details_for_extra_hours: "Booking Dtails For Extra Hours",
  add_extra_hours_label: "How much extra hours spent",
  enter_hours: "Enter Hours",
  extra_time_added: "Enter Hours Added",
  extra_hours_details: "Pricing information for extra hours",
  extra_hours_error_message: "Please enter a valid hour value greater than or equal to 1.",

  text_published: "Published",
  text_publish: "Publish",
  text_draft: "Draft",

  trending_topics: "Trending Topics",

  //refer and earn page
  refer_and_earn: "Refer and Earn",
  "vestibulum_ante_ipsum_primis_in_faucibus_orci_luctus_et_ultrices_posuere_cubilia_curae.supplies_it_with_the_necessary_regelialia.":
    "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.supplies it with the necessary regelialia.",
  credit_earned: "Credit Earned",
  this_is_your_unique_referral_link: "This is your unique referral link",
  copy_and_paste_it_anywhere_to_tell_your_friends_about_quickspot:
    "copy and paste it anywhere to tell your friends about Quickspot",
  this_is_your_unique_referral_code: "This is your unique referral code",
  share_it_with_your_friends_and_family:
    "Share it with your friends and family",
  or_invite_via_email: "Or Invite via Email",
  email_address: "Email Address",
  "please_enter_email.": "Please Enter Email.",
  invalid_email: "Invalid Email",
  message: "Message",
  "please_enter_message.": "Please Enter Message.",
  submit: "Submit",

  //update profile
  my_profile: "My Profile",
  update_profile: "Update Profile",
  update_the_following_information: "Update the following information",
  dob: "DOB",
  "cpf/cnpj": "CPF/CNPJ",

  //change password
  change_password: "Change Password",
  current_password: "Current Password",
  // update: "Update",
  update: "Publish",

  //payment method
  payment_method: "Payment Method",
  add_card: "Add Card",
  "card_holder's_name": "Card Holder's Name",
  card_number: "Card Number",
  expiry_month: "Expiry month",
  expiry_year: "Expiry year",
  cvv_number: "CVV Number",
  submit: "Submit",
  "adding_a_card_allows_quickspot_to_charge_you_for_reserved_bookings._this_information_is_securely_sent_to_our_payment_provider_and_never_stored_by_quickspot":
    "Adding a card allows Quickspot to charge you for reserved bookings. This information is securely sent to our payment provider and never stored by Quickspot",
  added_cards: "Added Cards",
  add_new_card: "Add New Card",
  billing_address: "billing_address",
  "are_you_sure?": "Are you sure?",
  "you_won't_be_able_to_revert_this!": "You won't be able to revert this!",
  "yes,_delete_it!": "Yes, delete it!",
  cancel: "Cancel",

  "used_when_processing_payouts_and_payments.":
    "Used when processing payouts and payments.",
  address_1: "Address 1",
  address_2: "Address 2",
  country: "Country",
  "state_(iso_format)": "State (ISO Format)",
  city: "City",
  postal_code: "Postal Code",
  update: "Update",
  update_address: "Update Address",

  //payouts
  payouts: "Payouts",
  no_account_added: "No Account Added",
  please_your_bank_account_details_so_we_can_send_you_your_payout_after_a_complete_booking:
    "Please your bank account details so we can send you your payout after a complete booking",
  add_your_bank: "Add Your Bank",
  update_your_bank: "Update Your Bank",
  holder_name: "Holder Name",
  holder_type: "Holder Type",
  holder_document: "Holder Document",
  bank: "Bank",
  branch_number: "Branch Number",
  branch_check_digit: "Branch Check Digit",
  account_number: "Account Number",
  account_check_digit: "Account Check Digit",
  type: "Type",
  // update: "Update",
  add: "Add",

  //my calendar
  my_calendar: "My Calendar",
  all_spots: "All Spots",

  //transaction
  transactions: "Transactions",
  transactions_subtitle:
    "Below are your points transaction details. After Looker has confirmed payment for the reservation by credit card, the amount will be forwarded 30 days to be processed and the transfer will be made automatically every Wednesday. A fee of R$3,67 per transfer will be deducted.",
  waiting_funds_amount: "waiting_funds_amount",
  available_amount: "available_amount",
  transferred_amount: "transferred_amount",
  search_location: "SEARCH LOCATION",
  bad: "Bad",
  average: "Average",
  good: "Good",
  excellent: "Excellent",
  poor: "Poor",
  "how_do_you_rate_us?": "How do you rate your experience with ",
  your_feedback: "Your feedback",
  submit_feedback: "Submit feedback",
  feedback: "Feedback",
  view_feedback: "View feedback",
  leave_a_feedback: "Leave a feedback",
  listings: "Listings",
  // delete_spot: "Delete spot",
  // "are_you_sure_you_want_to_delete_this_spot?":
  //   "Are you sure you want to delete this spot?",
  delete_portfolio: "Delete Portfolio",
  "are_you_sure_you_want_to_delete_this_portfolio?":
    "Are you sure you want to delete this portfolio? You won't be able to get it back.",
  delete_spot: "Delete Spot",
  "are_you_sure_you_want_to_delete_this_spot?":
    "Are you sure you want to delete this spot? You won't be able to get it back.",
  yes: "Yes",
  delete_: "Delete",
  view_spot_details: "View spot details",
  edit: "Edit",
  delete: "Delete",
  about_the_spot: "About the spot",
  see_more: "See More",
  see_less: "See Less",
  category: "Category",
  style: "Style",
  wishlist: "Wishlist",
  price: "PRICE",
  location: "Location",
  all_dates: "All dates",
  clear: "Clear",
  more_filters: "More filters",
  sort_by: "Sort by",
  price_ascending: "Price ascending",
  price_descending: "Price descending",
  space_found: "spots found",
  filter: "Filter",
  above_18: "Above 18",
  clear_all: "Clear all",
  view: "View",
  styles: "Styles",
  view_all: "View all",
  categories: "Categories",
  "no_spot_found,_please_try_with_different_info.":
    "No spot found, please try with different info.",
  all_categories: "All categories",
  approve: "Approve",
  reject: "Reject",
  Amenities: "Amenities",
  approve: "Approve",
  reject: "Reject",
  "These are amenities that Lookers typically look for in a spot. Select the ones that apply.":
    "These are amenities that Lookers typically look for in a spot. Select the ones that apply.",
  more: "More",
  please_enter_valid_postal_code: "please_enter_valid_postal_code",
  please_select_spot_category: "Please select spot category",
  profession: "Profession",
  portfolio: "Portfolio",
  "No Portfolio Found": "No Portfolio Found",

};
export default messages;
