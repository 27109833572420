import {
  loginTokenHeader,
  generalHeader,
  authTokenHeader,
} from "../auth-header";
import axios from "../Axios";
import { toast } from "react-toastify";
import { userApi } from "../../api";
import moment from "moment";

export const signUpApiCall = async (
  email,
  password,
  referralCode,
  marketingCheckbox
) => {
  const data = {
    email: email,
    password: password,
    email_notification: marketingCheckbox,
    referral_code: referralCode,
  };
  return await axios.post(userApi.signUp, data, {
    headers: loginTokenHeader(),
  });
};

export const getProfileApi = async (userId) => {
  return await axios.get(`${userApi.getCompleteProfile}/${userId}`, {
    headers: generalHeader(),
  });
};

export const completeProfileApiCall = async (data, userId) => {
  return await axios.post(`${userApi.completeProfile}/${userId}`, data);
};

export const updateProfileApiCall = async (data) => {
  return await axios.post(userApi.updateProfile, data, {
    headers: authTokenHeader(),
  });
};

export const resendVerificationCodeApiCall = async (userId, email) => {
  const data = {
    email: email,
  };
  return await axios.post(`${userApi.verificationCode}/${userId}`, data, {
    headers: generalHeader(),
  });
};

export const verifyUserApiCall = async (code, email) => {
  const data = {
    code: code,
    email: email,
  };
  return await axios.post(`${userApi.verifyUserApiCall}`, data, {
    headers: generalHeader(),
  });
};

export const loginApiCall = async (email, password) => {
  const formData = {
    email: email,
    password: password,
  };
  return await axios.post(userApi.login, formData, {
    headers: loginTokenHeader(),
  });
};

export const forgotPasswordApiCall = async (email) => {
  const formData = {
    email: email,
  };
  return await axios.post(userApi.forgotPassword, formData, {
    headers: generalHeader(),
  });
  // const response = await fetch(userApi.forgotPassword, {
  //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
  //     mode: 'no-cors', // no-cors, *cors, same-origin
  //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  //     credentials: 'same-origin', // include, *same-origin, omit
  //     headers: {
  //       'Content-Type': 'application/json'
  //       // 'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     redirect: 'follow', // manual, *follow, error
  //     referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  //     body: JSON.stringify(formData) // body data type must match "Content-Type" header
  //   });
  //   return response.json();
};

export const resetPasswordApiCall = async (email, key, password) => {
  const formData = {
    email: email,
    key: key,
    password: password,
  };
  return await axios.post(userApi.resetPassword, formData, {
    headers: generalHeader(),
  });
};

export const changePasswordApiCall = async (oldPassword, newPassword) => {
  const formData = {
    oldPassword,
    newPassword,
  };
  return await axios.post(`${userApi.changePassword}`, formData, {
    headers: authTokenHeader(),
  });
};
