// import { loginTokenHeader, generalHeader } from "../../auth-header";
import axios from "../Axios";
import { adminApi, userApi } from "../../api";
import _ from "lodash";

//Get a cms page by slug
export const getCmsPageBySlugApiCall = async (slug) => {
  return await axios.get(`${userApi.cmsPages}/slug/${slug}`);
};

//Get all cms pages
export const getCmsPagesApiCall = async (slug) => {
  return await axios.get(`${userApi.cmsPages}/user/pages`);
};
