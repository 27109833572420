import React, { useState, useEffect } from "react";
import { imagePath } from "../../../constants/imageUrl";
import { validation } from "../../../constants/constant";
import {
  AddListSpotApiCall,
  getSpotDetailsApiCall,
} from "../../../_services/listSpot/listSpot.service";
import ApiLoader from "../../../common/ApiLoader";
import { toast } from "react-toastify";
import { userRoutes } from "../../../Routes";
import { Link, useNavigate } from "react-router-dom";
import { getAllLookupApiCall } from "../../../_services/lookups/lookups.service";
import { postImageOnApiCall } from "../../../_services/image.service";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import AddOns from "./AddOns";
import { createLogs } from "../../../_services/logs/logs.service";
import { authTokenHeader } from "../../../_services/auth-header";

function Amenities({
  activeListSpotFun,
  handler,
  activeListSpot,
  setActiveListSpot,
}) {
  const params = useParams();
  const navigate = useNavigate();
  // Data
  const [addOnInput, setAddOnInput] = useState("");
  const [addOnFee, setAddOnFee] = useState("");
  const [image, setImage] = useState("");
  const [amenitiesData, setAmenitiesData] = useState([]);
  const [amenitiesCategoryData, setAmenitiesCategoryData] = useState([]);
  const [termsCondition, setTermsCondition] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [finalAmenitiesData, setFinalAmenitiesData] = useState([]);
  // Get api list of Amenitites

  const [amenitiesList, setAmenitiesList] = useState([]);
  const [chkAmentites, setChkAmentites] = useState([]);
  // Validation
  const [fileSizeError, setFileSizeError] = useState(false);
  const [addClick, setAddClick] = useState(false);
  const [submitted, setSubmitted] = useState();
  const [loader, setLoader] = useState();

  useEffect(() => {
    setLoader(true);
    // get category dropdown data

    getAllLookupApiCall({ qs_lookup_key: "qs_amenity" })
      .then((responseA) => {
        setLoader(false);

        setAmenitiesList(responseA?.data);
        getAllLookupApiCall({ qs_lookup_key: "qs_amenity_category" })
          .then((responseAC) => {
            setLoader(false);

            setAmenitiesCategoryData(
              responseAC?.data.filter((value) =>
                responseA.data.find(
                  (x) =>
                    x.qs_lookup_details.amenity_category_id ==
                    value.qs_lookup_id
                )
              )
            );
          })
          .catch((error) => {
            toast.error(JSON.stringify(error.response));
            setLoader(false);
          });
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response));
        setLoader(false);
      });
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    // For edit screen
    if (params.id) {
      setLoader(true);
      getSpotDetailsApiCall(params.id)
        .then((response) => {
          setLoader(false);
          let data = response?.data?.data[0];

          if (data?.qs_addons?.addons) {
            setAmenitiesData(data?.qs_addons?.addons);
          }
          if (data?.qs_amenities) {
            setFinalAmenitiesData(data?.qs_amenities);
          }
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response));
          setLoader(false);
        });
    }
  }, [activeListSpot === "spot-amenities"]);

  // Add Add-Ons
  const addAddOns = (e) => {
    e.preventDefault();
    setAddClick(true);
    let error = false;
    if (addOnInput === "" || addOnFee === "" || image === "") {
      error = true;
    }
    if (error) return;

    const data = {
      addOns: addOnInput,
      addOnFee: addOnFee,
      image: image,
      // image: ""
    };
    setAmenitiesData([...amenitiesData, data]);
    setAddOnInput("");
    setAddOnFee("");
    setImage("");
    setAddClick(false);
  };

  const removeImage = (index) => {
    const data = amenitiesData.filter((item, i) => i !== index);
    setAmenitiesData(data);
  };

  const handleSubmit = (e) => {
    setSubmitted(true);
    e.preventDefault();

    let error = false;
    if (termsCondition === false || privacyPolicy === false) error = true;
    if (error) return;
    // let mergeAmenetiesAddons = [...amenitiesData, ...finalAmenitiesData]

    const finalData = {
      formSerial: 4,
      handler: handler,
      isEdit: !!params.id,

      amenities: finalAmenitiesData,
      addons: amenitiesData,
    };

    setLoader(true);
    // Add list api call
    AddListSpotApiCall(finalData)
      .then((response) => {
        if (response.status === 200) {
          setLoader(false);
          navigate(userRoutes.myListing.path);
          window.scrollTo(0, 0);
        }
        if (response.code === 400) {
          toast.error(response.message);
        }
        if (response.status === 422) {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        createLogs({
          url: "/frontend/edit-list-spot?step=4",
          payload: JSON.stringify({
            error,
            values: finalData,
            headers: authTokenHeader(),
          }),
        });
      });
  };
  const getCheckAmenitiesData = (qs_lookup_id) => {
    // Ensure finalAmenitiesData is a copy to avoid mutating the original state directly
    const updatedFinalAmenitiesData = [...finalAmenitiesData];

    if (qs_lookup_id !== undefined) {
      const isItemExist = updatedFinalAmenitiesData.includes(qs_lookup_id);

      if (isItemExist) {
        // Remove the item if it already exists in the array
        const filteredData = updatedFinalAmenitiesData.filter(
          (existingItem) => existingItem !== qs_lookup_id
        );
        setFinalAmenitiesData(filteredData);
      } else {
        // Add the item if it doesn't exist in the array
        const newData = [...updatedFinalAmenitiesData, qs_lookup_id];
        setFinalAmenitiesData(newData);
      }
    }
  };

  return (
    <>
      {loader && <ApiLoader />}
      <h3 className="fw-semibold  mb-2">{t("Amenities")}</h3>
      <p className="text-default mb-3 fs-13">
        {t(
          "These are amenities that Lookers typically look for in a spot. Select the ones that apply."
        )}
      </p>
      <div className="overflow-hidden boxTilesMain">
        <div class="accordion" id="accordionExample">
          {amenitiesCategoryData.map((amenitiesCategory) => (
            <div class="accordion-item">
              <h2
                class="accordion-header"
                id={`heading${amenitiesCategory.qs_lookup_id}`}
              >
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${amenitiesCategory.qs_lookup_id}`}
                  aria-expanded="true"
                  aria-controls={`collapse${amenitiesCategory.qs_lookup_id}`}
                >
                  {amenitiesCategory.qs_lookup_details.title}
                </button>
              </h2>
              <div
                id={`collapse${amenitiesCategory.qs_lookup_id}`}
                class="accordion-collapse collapse"
                aria-labelledby={`heading${amenitiesCategory.qs_lookup_id}`}
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="row row-cols-3 row-cols-sm-4 row-cols-md-5 row-cols-lg-4 row-cols-xl-5 g-10">
                    {amenitiesList?.length <= 0 ? (
                      <p>{t("no_record_found")}</p>
                    ) : (
                      amenitiesList
                        ?.filter(
                          (value) =>
                            value.qs_lookup_details.amenity_category_id ==
                            amenitiesCategory.qs_lookup_id
                        )
                        .map((item, index) => (
                          <div className="col" key={item.qs_lookup_id}>
                            <div className="ratio ratio-boxTiles">
                              <div className="boxTilesInner rounded d-flex flex-column p-0 overflow-hidden">
                                <div className="form-check fs-16 h-100 m-0 ps-0 w-100 amenitiescheck">
                                  <input
                                    className="form-check-input me-2 mt-2 position-absolute end-0 top-0"
                                    type="checkbox"
                                    checked={finalAmenitiesData.includes(
                                      item.qs_lookup_id
                                    )}
                                    id={item.qs_lookup_id}
                                    name={item.qs_lookup_id}
                                    onClick={(e) => {
                                      getCheckAmenitiesData(item.qs_lookup_id);
                                    }}
                                  />
                                  {/* checked={finalAmenitiesData.includes(item.qs_lookup_id) } */}
                                  <label
                                    className="form-check-label h-100 rounded w-100"
                                    htmlFor={item.qs_lookup_id}
                                  >
                                    <div className="boxTilesInner border rounded d-flex flex-column h-100 w-100">
                                      {/* <i className={"fa " + item?.qs_lookup_details?.icon} ></i> */}
                                      <div className="boxTileIcon mb-auto">
                                        <img
                                          width={30}
                                          height={30}
                                          src={
                                            item?.qs_lookup_details?.image?.url
                                          }
                                        />

                                        {/* <i
                              className={
                                "fa fa-lg " + item?.qs_lookup_details?.icon
                              }
                            ></i> */}
                                      </div>
                                      <div className="boxTileTitle fs-13 fw-medium ">
                                        {item?.qs_lookup_details?.title}
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Add - Ons  */}
      <h3 className="fw-semibold mb-0 mt-4 pt-3">{t("add-ons")}</h3>
      <p className="text-default-400 mb-3 fs-15 pb-1">
        {t("add_your_own_amenities_with_their_fee.")}
      </p>
      <AddOns
        amenitiesData={amenitiesData}
        setAmenitiesData={setAmenitiesData}
      />
      <ul className="p-0 list-unstyled mt-3 mb-4 ow-cols-1 row-cols-lg-1 row-cols-md-1 row-cols-sm-1 row-cols-1  g-0 d-flex flex-wrap">
        <li>
          <div className="form-check mt-0">
            <input
              className="form-check-input "
              type="checkbox"
              value={termsCondition}
              onChange={(e) => setTermsCondition(e.target.checked)}
            />
            <label className="form-check-label text-default fs-13 ">
              {t("i_have_reed_and_agreed_to_the")}
              <Link
                to={userRoutes.termsConditions.path}
                className="text-link text-decoration-none"
                target={"_blank"}
              >
                {" "}
                {t("terms_and_conditions")}
              </Link>{" "}
              {t("from_quickspot")}
            </label>
            {submitted && !termsCondition && (
              <p className="fs-11 text-danger text-start">
                {t("please_select_terms_&_conditions.")}
              </p>
            )}
          </div>
        </li>
        <li>
          <div className="form-check mt-0">
            <input
              className="form-check-input"
              type="checkbox"
              value={privacyPolicy}
              onChange={(e) => setPrivacyPolicy(e.target.checked)}
            />
            <label className="form-check-label text-default fs-13 ">
              {t("i_have_reed_and_agreed_to_the")}
              <Link
                to={userRoutes.privacyPolicy.path}
                className="text-link text-decoration-none"
                target={"_blank"}
              >
                {" "}
                {t("privacy_policy")}
              </Link>{" "}
              {t("from_quickspot")}
            </label>
            {submitted && !privacyPolicy && (
              <p className="fs-11 text-danger text-start">
                {t("please_select_privacy_policy.")}
              </p>
            )}
          </div>
        </li>
      </ul>
      <div className="row justify-content-between ">
        <div className="col-md-3 col-6">
          <button
            type="button"
            className="btn btn-outline-secondary backbtn"
            onClick={() => {
              setActiveListSpot(3);
            }}
          >
            {t("back")}
          </button>
        </div>
        <div className="col-md-3 col-6  text-end text-md-end">
          {params.id ? (
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              {t("update")}
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              {t("list_spot")}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default Amenities;
