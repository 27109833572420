import React, { useContext, useTransition } from "react";
import "./style.scss";
import { imagePath } from "../../constants/imageUrl";
import { Link, useLocation, useNavigate, useRoutes } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import ProfileImg from "../../assets/images/userimg.svg";
import { adminRoutes, userRoutes } from "../../Routes";
import { logoutApiCall } from "../../_services/admin/auth.services";
import { useEffect } from "react";
import { getUnreadMessageCountApiCall } from "../../_services/Messages/inbox.services";
import { useState } from "react";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getProfileApi } from "../../_services/users/auth.services";
import { AuthenticationContext } from "../../contexts/Authentication.context";

function InnerHeader() {
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const navigator = useNavigate();
  const firstName = localStorage.getItem("user-firstName");
  const lastName = localStorage.getItem("user-lastName");
  const image = localStorage.getItem("user-image");
  const [isSellerAdded, setIsSellerAdded] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useContext(
    AuthenticationContext
  );

  useEffect(() => {
    // Get My listing data
    getProfileApi(localStorage.getItem("user-id")).then((response) => {
      if (response.data.data.pagar_seller_id) {
        setIsSellerAdded(true);
      }
    });
  }, []);

  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    getUnreadMessageCountApiCall().then((response) => {
      setUnreadMessageCount(parseInt(response.data.data[0].unread_count));
    });
  }, []);

  return (
    <header
      className={`${
        location.pathname == userRoutes.home.path ? "home-header" : null
      } bg-white border-bottom header sticky-top`}
    >
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container-fluid p-0">
          <Link className="navbar-brand" to={userRoutes.home.path}>
            <img src={Logo} className="img-fluid" alt="Home Logo" />
            {/* <img src={images("svgtest")} className="img-fluid" alt="Home Logo"/> */}
          </Link>
          {/* when user login mobile*/}
          <div className="dropdown ms-4 ms-auto me-3  ps-4 d-lg-none">
            <button
              className="btn btn-default ms-1 pt-1 pe-3 me-1 p-0 dropdown-toggle border-0 fs-15 fw-medium text-default"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="me-2 primg rounded-circle d-inline-block overflow-hidden">
                <img
                  src={image !== "undefined" ? image : ProfileImg}
                  className="me-2 "
                  alt="Profile Image"
                  height="38"
                  width="38"
                />
              </span>
              <span className="d-inline-block">
                {firstName != "" ? firstName : null}{" "}
                {lastName ? lastName : null}
              </span>
            </button>
            <ul
              className="dropdown-menu fs-13  end-0"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <Link
                  className="dropdown-item"
                  to={userRoutes.updateProfile.path}
                >
                  {t("profile")}
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to={userRoutes.referral.path}>
                  {t("refer_&_earn")}
                </Link>
              </li>
              {isSellerAdded && (
                <li>
                  <Link
                    className="dropdown-item"
                    to={userRoutes.transactions.path}
                  >
                    {t("transactions")}
                  </Link>
                </li>
              )}

              <li>
                <Link className="dropdown-item" to={userRoutes.listSpot.path}>
                  {t("add_spot")}
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to={userRoutes.helpPage.path}>
                  {t("faq_and_help_center")}
                </Link>
              </li>
              <li
                onClick={() => {
                  logoutApiCall().then((response) => {
                    // localStorage.clear();
                    localStorage.removeItem("user-firstName");
                    localStorage.removeItem("user-lastName");
                    localStorage.removeItem("user-id");
                    localStorage.removeItem("user-referral");
                    localStorage.removeItem("user-wishlist");
                    localStorage.removeItem("user-email");
                    localStorage.removeItem("user-image");
                    localStorage.removeItem("user-token");
                    setIsAuthenticated(false);
                    navigator(userRoutes.login.path);
                  });
                }}
              >
                <a className="dropdown-item">Logout</a>
              </li>
            </ul>
          </div>
          {/* when user login */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* when user login */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* when user login Desktop*/}
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className={`nav-link fs-13 fw-medium ${
                    location.pathname == userRoutes.home.path ? "active" : null
                  }`}
                  to={userRoutes.home.path}
                >
                  {" "}
                  {t("home")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link fs-13 fw-medium ${
                    location.pathname == userRoutes.inbox.path ? "active" : null
                  }`}
                  to={userRoutes.inbox.path}
                >
                  {t("inbox")}
                  {unreadMessageCount != 0 ? (
                    <Badge pill>{unreadMessageCount}</Badge>
                  ) : null}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link fs-13 fw-medium ${
                    location.pathname == userRoutes.myBookings.path
                      ? "active"
                      : null
                  }`}
                  to={userRoutes.myBookings.path}
                >
                  {" "}
                  {t("my_bookings")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link fs-13 fw-medium ${
                    location.pathname == userRoutes.myListing.path
                      ? "active"
                      : null
                  }`}
                  to={userRoutes.myListing.path}
                >
                  {t("my_listings")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link fs-13 fw-medium ${
                    location.pathname == userRoutes.myWishlist.path
                      ? "active"
                      : null
                  }`}
                  to={userRoutes.myWishlist.path}
                >
                  {t("my_wishlist")}
                </Link>
              </li>
              <li className="dropdown ms-4 border-start ps-4 d-none d-lg-block">
                <button
                  className="btn btn-default ms-1 pt-1 pe-3 me-1 p-0 dropdown-toggle border-0 fs-15 fw-medium text-default"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="me-2 primg rounded-circle d-inline-block overflow-hidden">
                    <img
                      src={image !== "undefined" ? image : ProfileImg}
                      className="me-2 "
                      alt="Profile Image"
                      height="38"
                      width="38"
                    />
                  </span>
                  <span className="d-inline-block">
                    {firstName != "" ? firstName : null}{" "}
                    {lastName ? lastName : null}
                  </span>
                </button>
                <ul
                  className="dropdown-menu fs-13  end-0"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <Link
                      className="dropdown-item"
                      to={userRoutes.updateProfile.path}
                    >
                      {t("profile")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to={userRoutes.referral.path}
                    >
                      {t("refer_&_earn")}
                    </Link>
                  </li>
                  {isSellerAdded && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={userRoutes.transactions.path}
                      >
                        {t("transactions")}
                      </Link>
                    </li>
                  )}

                  <li>
                    <Link
                      className="dropdown-item"
                      to={userRoutes.listSpot.path}
                    >
                      {t("add_spot")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to={userRoutes.helpPage.path}
                    >
                      {t("faq_and_help_center")}
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      logoutApiCall().then((response) => {
                        // localStorage.clear();
                        localStorage.removeItem("user-firstName");
                        localStorage.removeItem("user-lastName");
                        localStorage.removeItem("user-id");
                        localStorage.removeItem("user-referral");
                        localStorage.removeItem("user-wishlist");
                        localStorage.removeItem("user-email");
                        localStorage.removeItem("user-image");
                        localStorage.removeItem("user-token");
                        localStorage.removeItem("isSocialLogin");

                        navigator(userRoutes.login.path);
                      });
                    }}
                  >
                    <a className="dropdown-item" href="#">
                      {t("logout")}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            {/* when user login */}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default InnerHeader;
