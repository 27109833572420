import { t } from "i18next";

export const validation = {
  emptyEmailField: t("please_enter_email."),
  invalidEmail: t("email_is_invalid."),
  emptyPasswordField: t("please_enter_password."),
  emptyConfirmPasswordField: t("please_enter_confirm_password."),
  // invalidPassword:
  //   "Password must contain Digit, Lower case, Upper case, special Characters and min 8 char max 32 char.",
  invalidPassword: t("invalid_password"),
  passwordDescription: t(
    "password_must_contain_digit,_lower_case,_upper_case,_special_characters,_min_8_char_and_max_32_char."
  ),
  confirmPasswordNotMatched: t("confirm_Password_does_not_match_password."),
  acceptServiceAgreement: t("please_accept_agreement"),
  referralCodeRequired: t("please_enter_referral_code"),
  emptyNewPasswordField: t("please_enter_new_password"),
  emptyNewConfirmPasswordField: t("please_enter_confirm_new_password"),
  confirmNewPasswordNotMatched: t(
    "confirm_new_password_does_not_match_new_password."
  ),
  firstNameEmpty: t("please_enter_first_name."),
  lastNameEmpty: t("please_enter_last_name."),
  phoneNumberRequired: t("please_enter_phone_number."),
  CPFCNPJrequired: t("please_enter_CPF/CNPJ#."),
  DOBRequired: t("please_enter_date_of_birth."),
  companyNameRequired: t("please_enter_company_name."),
  invalidPhoneNumber: t("phone_number_must_be_11_digits"),
  invalidCPFCNPJ: t("CPF/CNPJ#_is_invalid."),
  currentPasswordRequired: t("please_enter_current_password."),
  newPasswordRequired: t("please_enter_new_password."),
  retypeNewPasswordRequired: t("please_enter_confirm_new_password."),
  matchNewPassword: t("password_doesn't_match."),
  invalidName: t("invalid_name,_only_contains_alphabets."),
  imageUpladSize: t("image_size_cannot_be_greater_than_1MB."),
  belowAge: t("you_are_below_18."),
  selectSpotCategory: t("please_select_spot_category"),
  selectSpotStyle: t("please_select_spot_style"),
  spotTitleRequired: t("please_enter_spot_title."),
  hoursRequired: t("please_enter_valid_hours."),
  invalidHours: t("please_enter_valid_hours_between_1_-_5000."),
  invalidDiscount: t(
    "please_enter_valid_discount_in_percentage_between_1_-_99."
  ),
  minBookingHrRequired: t("please_enter_valid_minimum_booking_hours."),
  spotDescriptionLimit: t("please_enter_minimum_50_characters_in_description."),
  spotRulesLimit: t("please_enter_minimum_5_characters_in_rules."),
  invalidMinBooking: t("please_enter_valid_booking_hours_between_1_-_999."),
  maxGuestsRequired: t("please_enter_maximum_guests_allowed."),
  invalidMaxBooking: t("maximum_8_guests_can_be_added."),
  spotDescriptionRequired: t("please_enter_spot_description."),
  spotStyleRequired: t("please_select_spot_style"),
  streetAddressRequired: t("please_enter_street_address"),
  streetAddressFromDropdown: t(
    "please_select_street_address_from_suggestions."
  ),
  cityRequired: t("please_enter_city."),
  postalCodeRequired: t("please_enter_postal_code."),
  rulesInsturctionsRequired: t("please_enter_rules_&_instructions."),
  ageGroupRequired: t("please_enter_age_group."),
  cleaningFeeRequired: t("please_enter_cleaning_Fee."),
  invalidCleaningFee: t("please_enter_valid_cleaning_fee_between_1_-_999."),
  securityRequired: t("please_select_security_of_spot."),
  petFriendlyRequired: t("please_select_pet_friendly."),
  parkingOptionsRequired: t("please_select_parking_options."),
  cancelationPolicyRequired: t("please_select_cancellation_policy."),
  availableParkingRequired: t("please_select_available_parking_facilities"),
  additionalParkingInstructionsRequired: t(
    "please_enter_additional_parking_instructions."
  ),
  captchaRequired: t("please_enter_captcha."),
  selectStartOrEndTime: t("please_select_start_time_and_end_time."),
  selectWeekend: t("please_select_weekend"),
  addDates: t("please_add_slots."),
  minFiveImages: t("please_add_minimum_five_images"),
  selectSelectedDays: t("please_select_days"),
  enterOtp: t("please_enter_otp."),
  validTime: t("please_enter_valid_time_slot."),
  enterFieldAddOns: t("please_enter_add-ons."),
  enterFieldFee: t("please_enter_fee."),
  uploadAddOnPic: t("please_upload_picture."),
  requiredtermsCondition: t("please_select_terms_&_conditions."),
  requiredPrivacyPolicy: t("please_select_privacy_policy."),
  slotBooked: t("this_slot_is_already_taken,_please_select_different_slot."),
  noRecordFound: t("no_record_found."),
  selectDateAndTime: t("please_select_date_&_time."),
  invalidPostalCode: t("please_enter_valid_postal_code."),
  messageRequired: t("please_enter_message."),
};
