import { authTokenHeader } from "../auth-header";
import axios from "../Axios";
import { userApi } from "../../api";

// Chat Initiate Api Call
export const chatInitiateApiCall = async (data) => {
  return await axios.post(userApi.chatInitiate, data, {
    headers: authTokenHeader(),
  });
};

// List of users (data showing in left pannel)
export const listOfChatUsersApiCall = async (params) => {
  return await axios.get(userApi.chatUsers, {
    params,
    headers: authTokenHeader(),
  });
};

// Complete chat api call
export const oneToOneChatApiCall = async (params) => {
  return await axios.get(`${userApi.oneToOneChat}`, {
    params,
    headers: authTokenHeader(),
  });
};

// send message or chat api call
export const sendMessageApiCall = async (data) => {
  return await axios.post(userApi.chatUsers, data, {
    headers: authTokenHeader(),
  });
};

// send message or chat api call
export const fileUploadApiCall = async (data, setFileUploadPercentage) => {
  return await axios.post(userApi.fileUpload, data, {
    headers: authTokenHeader(),
    onUploadProgress: (progressEvent) => {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = parseInt(percentComplete * 100);
      setFileUploadPercentage(percentComplete);
    },
  });
};

// send message or chat api call
export const getUnreadMessageCountApiCall = async () => {
  return await axios.get(userApi.unreadMessageCount, {
    headers: authTokenHeader(),
  });
};
