import { createContext, useEffect, useState } from "react";

export const AuthenticationContext = createContext();

/**
 * Define a provider component for the ProfileContext
 *
 * @param {{ children: any; }} { children }
 * @returns {JSX.Element}
 */
export const AuthenticationProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    let auth = localStorage.getItem("user-token") ? true : false;
    setIsAuthenticated(auth);
  }, []);

  return (
    <AuthenticationContext.Provider
      value={[isAuthenticated, setIsAuthenticated]}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
