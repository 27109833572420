import { loginTokenHeader, generalHeader } from "../auth-header";
import axios from "../Axios";
import { adminApi, userApi } from "../../api";
import _ from "lodash";

//Add categories, amenaties and style data
export const addLookupApiCall = async (data) => {
  const { qs_lookup_key, description, status, ...rest } = data;
  const formData = {
    qs_lookup_key: qs_lookup_key,
    qs_lookup_desc: description,
    status: status,
    qs_lookup_details: rest,
  };
  return await axios.post(adminApi.lookups, formData, {
    headers: loginTokenHeader(),
  });
};

//Get All categories, amenaties and style data
export const getAllLookupApiCall = async (data) => {
  const { qs_lookup_key } = data;
  return await axios.get(
    adminApi.lookups,
    {
      params: {
        qs_lookup_key: qs_lookup_key,
      },
    },
    { headers: loginTokenHeader() }
  );
};

//Get All categories, amenaties and style data
export const getAllLookupApiCallM = async (data) => {
  const { qs_lookup_key } = data;
  return await axios.get(
    adminApi.mlookups,
    {
      params: {
        qs_lookup_key: qs_lookup_key,
      },
    },
    { headers: loginTokenHeader() }
  );
};

//Get All categories data in ascending order
export const getAscAllLookupApiCall = async (data) => {
  const { qs_lookup_key } = data;
  return await axios.get(`${userApi.getlookupDataAsc}/qs_category`, {
    headers: loginTokenHeader(),
  });
};

//Get All City data in ascending order
export const getAscAllCityApiCall = async (data) => {
  const { qs_lookup_key } = data;
  return await axios.get(`${userApi.getlookupDataAsc}/qs_city`, {
    headers: loginTokenHeader(),
  });
};

//Get a category, amenaty and style data by id
export const getLookupApiCall = async (id) => {
  return await axios.get(`${adminApi.lookups}/${id}`, {
    headers: loginTokenHeader(),
  });
};

//Get All categories, amenaties and style data
export const deleteLookupApiCall = async (id) => {
  return await axios.delete(`${adminApi.lookups}/${id}`, {
    headers: loginTokenHeader(),
  });
};

//Update a category, amenaty and style data by id
export const updateLookupApiCall = async (id, data) => {
  let { qs_lookup_key, description, status, ...rest } = data;
  if (_.isEmpty(rest)) {
    rest = undefined;
  }
  const formData = {
    qs_lookup_key: qs_lookup_key,
    qs_lookup_desc: description,
    status: status,
    qs_lookup_details: rest,
  };
  return await axios.patch(`${adminApi.lookups}/${id}`, formData, {
    headers: loginTokenHeader(),
  });
};
