import axios from "axios";
import LocalStorageService from "./LocalStorageService";
// import  i18n  from './../../../locale/i18n';

const baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(
  (config) => {
    const token = LocalStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
      // config.headers["X-Matter"] = LocalStorageService.getMatter();
      //config.headers["X-Client"] = LocalStorageService.getClient();
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
    config.headers["lang"] = LocalStorageService.getLocale();
    config.baseURL = baseURL;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (!error.response) {
      return Promise.reject(error);
    }

    // Not Authorized
    if (error.response.status === 401) {
      localStorage.clear();
    }

    if (error.response.status === 500) {
      if (error.response.data.message === "invalid signature") {
        localStorage.clear();
        window.location.reload();
        return;
      }
      if (error.response.data.message === "jwt expired") {
        localStorage.clear();
        window.location.reload();
        return;
      }
      //
    }
    // if (error.response.status === 401 && originalRequest.url === "/v1/sso/refresh-token"
    // ) {
    //   Message({
    //       message: ('Sorry your session has been expired'),
    //       type: 'error'
    //     });
    //   Message({
    //     message: i18n.t('sessio_expire_text'),
    //     type: 'error'
    //   });
    //   LocalStorageService.clearToken();
    //   return window.location = error.response.data.url;
    // }

    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   return axios
    //     .post("/v1/sso/refresh-token", {
    //       refresh_token: LocalStorageService.getRefreshToken(),
    //     })
    //     .then((res) => {
    //       if (res.status === 201) {
    //         // 1) put token to LocalStorage
    //         LocalStorageService.setToken(res.data.data);
    //         // 2) Change Authorization header
    //         axios.defaults.headers.common["Authorization"] =
    //           "Bearer " + LocalStorageService.getAccessToken();
    //         // 3) return originalRequest object with Axios.

    //         return axios(originalRequest);
    //       } else {
    //         //LocalStorageService.clearToken();
    //          if (error.response.data) {
    //             if (error.response.data.data) {
    //               if(error.response.data.data.status === false){
    //                 let matter = LocalStorageService.getMatter();
    //                   Message({
    //                     message: i18n.t('sessio_expire_text'),
    //                     type: 'error'
    //                   });
    //                   LocalStorageService.clearToken();
    //                   return window.location = error.response.data.data.url;
    //               //  return window.location = `/login`;
    //               //window.location = `/login?matter_id=${matter}`;
    //               //      return Promise.reject(error);
    //               }
    //             }
    //           }
    //         }
    //     });
    // }

    // if (error.response.status === 500) {
    //   if (error.response.data) {
    //     if (error.response.data && error.response.data.code===0) {
    //      // return this.props.history.push(`/not-permission`);
    //       //window.history.pushState({}, null, `/not-permission`);
    //       return window.location = "/not-permission";
    //     }
    //   }
    // }

    return Promise.reject(error);
  }
  //return Promise.reject(error);
);

export default axios;
