import React from "react";
import ReactDOM from "react-dom/client";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./locales/i18n";
import "moment/locale/pt";
import { GeneralSettingProvider } from "./contexts/GeneralSetting.context";
import { AuthenticationProvider } from "./contexts/Authentication.context";

const root = ReactDOM.createRoot(document.getElementById("root"));
//React.StrictMode mode run API Twice
root.render(
  <AuthenticationProvider>
    <GeneralSettingProvider>
      <App />
    </GeneralSettingProvider>
  </AuthenticationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
