import React from "react";
import { Link } from "react-router-dom";
import { useTranslation, withTranslation, Trans } from "react-i18next";

import "./style.scss";
import { imagePath } from "../../constants/imageUrl";

import Logo from "../../assets/images/logo.svg";
import ProfileImg from "../../assets/images/profileimg.png";
import { userRoutes } from "../../Routes";

function Header() {
  const { t, i18n } = useTranslation();

  return (
    <header className="bg-white border-bottom header sticky-top">
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container-fluid p-0">
          <Link className="navbar-brand" to={userRoutes.home.path}>
            <img src={Logo} className="img-fluid" alt="Home Logo" />
            {/* <img src={images("svgtest")} className="img-fluid" alt="Home Logo"/> */}
          </Link>

          {/* without login mobile*/}
          <ul className="navbar-nav  d-lg-none  mb-0 mb-lg-0 d-flex flex-row align-items-center ms-auto">
            <li className="nav-item ms-3 ">
              <Link
                className=" nav-link p-0"
                title="List Your Spot"
                to={userRoutes.publicList.path}
              >
                {imagePath.listIcon}
              </Link>
            </li>
            <li className="nav-item ms-3 ">
              <Link
                className="nav-link p-0"
                title="Login"
                to={userRoutes.login.path}
              >
                <i>{imagePath.loginIcon}</i>{" "}
              </Link>
            </li>
            <li className="nav-item ms-3">
              <Link
                className="nav-link p-0"
                title="Signup"
                to={userRoutes.signup.path}
              >
                <i>{imagePath.signUpIcon}</i>{" "}
              </Link>
            </li>
          </ul>
          {/* without login */}

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* without login Desktop*/}
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item ">
                <Link
                  className=" btn btn-primary"
                  aria-current="page"
                  to={userRoutes.publicList.path}
                >
                  {" "}
                  {t("list_your_spot")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={userRoutes.login.path}>
                  <i>{imagePath.loginIcon}</i> {t("login")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={userRoutes.signup.path}>
                  <i>{imagePath.signUpIcon}</i> {t("sign_up")}
                </Link>
              </li>
            </ul>
            {/* without login */}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
