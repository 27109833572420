import { createContext, useEffect, useState } from "react";
import { getGlobalSettingApiCall } from "../_services/globalSettings/GlobalSettings";
export const GeneralSettingContext = createContext();

/**
 * Define a provider component for the ProfileContext
 *
 * @param {{ children: any; }} { children }
 * @returns {JSX.Element}
 */
export const GeneralSettingProvider = ({ children }) => {
  // Initialize state for profile information
  const [generalSetting, setGeneralSetting] = useState({
    service_tax_percentage: 0,
  });

  useEffect(() => {
    getGlobalSettingApiCall().then((res) => {
      setGeneralSetting(res.data);
    });
  }, []);

  return (
    <GeneralSettingContext.Provider value={[generalSetting, setGeneralSetting]}>
      {children}
    </GeneralSettingContext.Provider>
  );
};
