import { t } from "i18next";
import React from "react";

export default function ProgressBar({
  activeListSpot,
  setActiveListSpot,
  completedStep,
  setCompletedStep,
}) {
  const tabs = [
    { id: 1, label: "spot_details", added: true, disabled: false },
    { id: 2, label: "spot_pictures", added: true, disabled: false },
    { id: 3, label: "operating_hours", added: true, disabled: false },
    { id: 4, label: "amenities", added: true, disabled: false },
  ];

  return (
    <div className="col-xxl-9 col-xl-9 col-lg-11 custompd">
      <div className="px-lg-3 mx-xl-2 mx-xxl-0">
        <ul
          className="nav nav-tabs w-100 ow-cols-2 row-cols-lg-4 row-cols-md-4 row-cols-sm-4  g-0 border-0 mb-0 row-cols-4 "
          id="myTab"
          role="tablist"
        >
          {tabs.map((tab) => (
            <li
              key={tab.id}
              className={
                tab.id < completedStep ? "nav-item complete" : "nav-item"
              }
              role="presentation"
            >
              <button
                disabled={tab.id > completedStep}
                onClick={() => setActiveListSpot(tab.id)}
                className={`nav-link mx-auto fw-semibold border-0 lh-base ${
                  activeListSpot === tab.id ? "active" : ""
                }`}
                type="radio"
                name="radio"
                id="radio"
                checked={activeListSpot === tab.id}
              >
                <div className="circle rounded-circle mx-auto position-relative "></div>
                <span>{t(tab.label)}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
