import React, { useState } from "react";
import { imagePath } from "../../../constants/imageUrl";
import { Formik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import { toast } from "react-toastify";
import { postImageOnApiCall } from "../../../_services/image.service";

export default function AddOns({
  amenitiesData,
  setAmenitiesData,
  addAddOns,
  addClick,
}) {
  const [fileSizeError, setFileSizeError] = useState(false);
  const [loader, setLoader] = useState();
  const [image, setImage] = useState("");
  // Choose or Browse Image
  const fileSelectedHandler = (e, setFieldValue) => {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      if (file.size / 1024 > 1024) {
        setFileSizeError(true);
      } else {
        if (reader.readyState === 2) {
          setFileSizeError(false);
          setLoader(true);
          postImageOnApiCall(file).then((response) => {
            if (response.status === 200) {
              setLoader(false);
              setImage((image) => [...image, response?.data?.imagePath]);
              setFieldValue("image", response?.data?.imagePath);
              toast.success("A imagem foi adicionada com sucesso!");
            }
            if (response.code === 400) {
              toast.error(response.message);
            }
            if (response.status === 422) {
              toast.error(response.message);
            }
          });

          // setImage(reader.result)
          // setProfilePic(file)
        }
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const removeImage = (index) => {
    const data = amenitiesData.filter((item, i) => i !== index);
    setAmenitiesData(data);
  };

  return (
    <>
      <Formik
        initialValues={{
          addOns: "",
          addOnFee: "",
          image: "",
        }}
        onSubmit={(values, actions) => {
          const data = {
            addOns: values.addOns,
            addOnFee: values.addOnFee,
            image: image,
            // image: ""
          };
          setAmenitiesData([...amenitiesData, data]);
          actions.resetForm();
        }}
        validationSchema={Yup.object().shape({
          addOns: Yup.string()
            .required(t("please_enter_add-ons."))
            .min(1, "Mínimo de 1 caractere")
            .max(50, "Máximo de 50 caracteres"),
          addOnFee: Yup.number()
            .min(1, "Mínimo de 1 real")
            .max(1000, "Máximo de 1000 reais")
            .required(t("please_enter_fee.")),
          image: Yup.string().required("Please upload picture."),
        })}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <div className="row w-100 me-0 ms-0 g-0">
            <div className="col-md-4">
              <div className="form-floating mb-3 me-lg-3 me-md-2 pe-md-1 pe-0 position-relative">
                <div className="position-absolute start-0 top-0 mt-10 text-default w-36 text-center pe-none">
                  {imagePath.addOn}
                </div>
                <input
                  id="addOns"
                  name="addOns"
                  type="text"
                  className="form-control rounded ps-36"
                  placeholder="Add-on"
                  onChange={handleChange}
                  value={values.addOns}
                />
                <label className="ms-4">Adicionais</label>

                {touched.addOns && errors.addOns && (
                  <p className="fs-11 text-danger text-start">
                    {errors.addOns}
                  </p>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-floating mb-3 me-lg-3 me-md-2 pe-md-1 pe-0 position-relative">
                <div className="position-absolute start-0 top-0 mt-10 text-default w-36 text-center pe-none">
                  {imagePath.ratedollar}
                </div>
                <input
                  id="addOnFee"
                  name="addOnFee"
                  type="number"
                  className="form-control rounded ps-36"
                  placeholder="Fee"
                  onChange={handleChange}
                  value={values.addOnFee}
                />
                <label className="ms-4">{t("fee")}</label>
                {touched.addOnFee && errors.addOnFee && (
                  <p className="fs-11 text-danger text-start">
                    {errors.addOnFee}
                  </p>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="btn-group1 d-flex w-100 justify-content-between align-items-start">
                {/* <button className="btn btn-outline-secondary me-lg-3 me-md-2 me-2 w-100">
                        Upload Picture
                    </button> */}
                <div className=" me-2 btn btn-outline-secondary uploadbtn p-0  position-relative bg-transparent">
                  <label
                    htmlFor="filePicker"
                    className=" border-0 h-100  w-100 fs-10 fw-medium bg-transparent  text-default-300 mx-auto "
                  >
                    {t("upload_image")}
                  </label>

                  <input
                    id="filePicker"
                    style={{ visibility: "hidden" }}
                    className="form-control flpck position-absolute start-0 top-0 end-0 bottom-0"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={(e) => fileSelectedHandler(e, setFieldValue)}
                  />
                </div>
                <button className="btn btn-primary" onClick={handleSubmit}>
                  {t("add")}
                </button>
                <div className="dropboximgbox opacity-0 d-none"></div>
              </div>
              {errors.image && (
                <p className="fs-11 text-danger text-start">
                  {t("please_upload_picture.")}
                </p>
              )}
              {fileSizeError && (
                <p className="fs-11 text-danger m-0 ">
                  {t("image_size_cannot_be_greater_than_1MB.")}
                </p>
              )}
            </div>
            <div className="col-md-12">
              <div className="dropboximgbox addedImages">
                {amenitiesData.map((item, index) => (
                  <>
                    <div className="position-relative " key={index}>
                      <div
                        onClick={() => removeImage(index)}
                        className="closeicon rounded-circle text-center d-flex align-items-center justify-content-center text-white position-absolute"
                      >
                        {imagePath.closeicon}
                      </div>
                      <div className="imageBox overflow-hidden">
                        <img
                          alt="Uploaded Image"
                          src={`${process.env.REACT_APP_API_URL}${item.image}`}
                        />
                      </div>

                      <div className="boxTilesCaption mt-1 text-center">
                        <div className="fw-medium text-truncate">
                          {item.addOns}
                        </div>
                        <div className="fs-11 fw-medium text-default-400">
                          {item.addOnFee}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}
