import React from 'react'

const ApiLoader = () => {
  return (

    <div className="lds-roller start-0 top-0 h-100 w-100 position-fixed d-flex align-items-center justify-content-center"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

  )
}

export default ApiLoader