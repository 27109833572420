import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { imagePath } from "../../constants/imageUrl";
import FootLogo from "../../assets/images/footer_logo.png";
import { getGlobalSettingApiCall } from "../../_services/globalSettings/GlobalSettings";
import { toast } from "react-toastify";
import { userRoutes } from "../../Routes";
import parse from "url-parse";
import { useTranslation } from "react-i18next";
import { AuthenticationContext } from "../../contexts/Authentication.context";

function Footer() {
  const [loader, setLoader] = useState();
  const [data, setData] = useState();
  const [isAuthenticated] = useContext(AuthenticationContext);
  const { t } = useTranslation();
  useEffect(() => {
    getGlobalSettingApiCall().then(
      (resp) => {
        setLoader(false);
        setData(resp?.data);
      },
      (error) => {
        setLoader(false);
        const err = error?.response?.data;
        if (err?.status === 400) {
          toast.error(err?.message);
        }
      }
    );
  }, []);

  return (
    <footer className="footer mt-auto">
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-3 col-6  text-center">
            <h5>{t("quick_links")}</h5>
            <ul className="foot_link list-unstyled ">
              {/* <li>
                <Link to="#"> Who Are Us</Link>
              </li> */}
              <li>
                <Link
                  to={
                    isAuthenticated
                      ? userRoutes.listSpot.path
                      : userRoutes.publicList.path
                  }
                >
                  {" "}
                  {t("list_your_spot")}
                </Link>
              </li>
              <li>
                <Link to={userRoutes.blogs.path}> {t("blog")}</Link>
              </li>
              <li>
                <Link to={userRoutes.contactUs.path}>{t("contact")}</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-3 col-6">
            <h5>{t("support")}</h5>
            <ul className="foot_link list-unstyled ">
              <li>
                <Link to={userRoutes.helpPage.path}>
                  {t("faq_&_help_center")}
                </Link>
              </li>

              {/* <li>
                <Link to="#"> Trust & Safety</Link>
              </li> */}
            </ul>
          </div>
          <div className="col-lg-2 col-md-3 col-6">
            <h5> {t("legal")}</h5>
            <ul className="foot_link list-unstyled ">
              <li>
                <Link to={userRoutes.termsConditions.path}>
                  {" "}
                  {t("terms_&_conditions")}
                </Link>
              </li>
              <li>
                <Link to={userRoutes.privacyPolicy.path}>
                  {t("privacy_policy")}
                </Link>
              </li>
              <li>
                <Link to={userRoutes.cancellationPolicies.path}>
                  {t("cancellation_policies")}
                </Link>
              </li>
              <li>
                {/* <Link to={userRoutes.refundPolicies.path}>
                  {" "}
                  {t("refund_policies")}
                </Link> */}
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-3 col-6">
            <h5>{t("social_links")}</h5>
            <ul className="social_link list-unstyled ">
              <li>
                <a
                  target="_blank"
                  href={
                    data?.facebook_url?.includes("https")
                      ? data?.facebook_url
                      : `https://${data?.facebook_url}`
                  }
                >
                  <i>{imagePath.facebook}</i>Facebook{" "}
                </a>
              </li>
              <li>
                {/* <a target="_blank" href={data?.instagram_url}> */}
                <a
                  target="_blank"
                  href={
                    data?.instagram_url?.includes("https")
                      ? data?.instagram_url
                      : `https://${data?.instagram_url}`
                  }
                >
                  <i>{imagePath.instagram}</i>Instagram
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href={
                    data?.pinterest?.includes("https")
                      ? data?.pinterest
                      : `https://${data?.pinterest}`
                  }
                >
                  <i>{imagePath.pinterest}</i>Pinterest
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href={
                    data?.google_url?.includes("https")
                      ? data?.google_url
                      : `https://${data?.google_url}`
                  }
                >
                  <i>{imagePath.linkedin}</i>Linkedin
                </a>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 text-lg-end text-center">
            <Link to={userRoutes.home.path} className="foot_logo">
              <img src={FootLogo} className="img-fluid" alt="Footer Logo" />
            </Link>
            <p className="mb-0 copyright">
              {data?.address}
              {/* Copyrights © <Link to="#">Quickspot</Link>. All Rights Reserved */}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
