import React, { useContext } from "react";
import {
  Outlet,
  Route,
  Redirect,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
// import { Navigation } from 'swiper';
import { routes, userRoutes } from "./Routes";
import { AuthenticationContext } from "./contexts/Authentication.context";

// export const PrivateRoute = ({ component: Component, ...rest }) => {
//     // const navigate = useNavigate();
//     let urlType = window.location.pathname ? true : false;
//     return (
//         < Outlet {...rest} render={props => (
//             localStorage.getItem('user-token')
//                 ? <Component {...props} />
//                 :
//                 (urlType)
//                     ?
//                     // <Redirect to={routes.login.path} />
//                     // navigate(userRoutes.login.path)
//                     <Navigate to={userRoutes.login.path} />
//                     :
//                     null
//         )} />
//     )
// }

export const PrivateRoute = () => {
  const [isAuthenticated] = useContext(AuthenticationContext);

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={`${userRoutes.login.path}`} />
  );
};
